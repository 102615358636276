import {
	AutoFactory,
	OnlinePortalBankUrlsService,
	OnlinePortalMessage,
	OnlinePortalMessagesService,
	OnlinePortalAuthorizationSettingsService,
	DynamicApplicationAutoLockSettingsService,
	DynamicApplicationSettingValue,
	LoanApplication,
	DynamicApplicationPerApplicationTypeSettingsService,
	PerApplicationTypeSettings,
	CollectionResponseDynamicApplicationSettingValue,
	CollectionResponseOnlinePortalMessage,
	DynamicApplicationEsignatureLinesSettingsService,
	DynamicApplicationSettingsService
} from '@sageworks/jpi';
import { BANK_CUSTOMER_USER_SESSION_LIFETIME_MINUTES, ONE_MINUTE_IN_MS } from '@/utils/session-utils';
import { defineModule } from 'direct-vuex';
import { getOnlinePortalSubdomain } from '@/utils/UrlUtils';
import { UrlUtils } from '@/utils';
import { moduleActionContext, moduleGetterContext } from '@/store/index';
import PerApplicationTypeSettingEnum = PerApplicationTypeSettings.PerApplicationTypeSettingEnum;
import DynamicApplicationSettingEnum = DynamicApplicationSettingValue.DynamicApplicationSettingEnum;

export interface InstitutionSettingsModuleState {
	institutionLogoUrl: string;
	logoutUrl: string;
	sessionLifetimeInMinutes: number;
	loginMessage: OnlinePortalMessage;
	homepageMessage: OnlinePortalMessage;
	contactMessage: OnlinePortalMessage;
	documentsMessage: OnlinePortalMessage;
	submitApplicationPreMessage: OnlinePortalMessage;
	submitApplicationPostMessage: OnlinePortalMessage;
	applicationCssElement: HTMLElement | null;
	applicationAutoLockSettings: Map<DynamicApplicationSettingValue.DynamicApplicationSettingEnum, boolean>;
	useBorrowerAccessRestrictionsSetting: DynamicApplicationSettingValue | null;
	allowBorrowerToSendInvitesSetting: DynamicApplicationSettingValue | null;
	allowBorrowerToAddRemoveLoansSetting: DynamicApplicationSettingValue | null;
	allowUserToAddRemoveLoansSetting: DynamicApplicationSettingValue | null;
	autoSavePdfToDocLibOnSubmission: DynamicApplicationSettingValue | null;
	shouldMatchGeneralDocumentsPageMessage: DynamicApplicationSettingValue | null;
	messagesPromise: Promise<CollectionResponseOnlinePortalMessage> | null;
	showEsignLinesSettings: Map<LoanApplication.TypeEnum, boolean> | null;
	allowBorrowersToStartApplications: DynamicApplicationSettingValue | null;
	showRefinanceQuestion: DynamicApplicationSettingValue | null;
	borrowerIsNotAllowedToAddDeleteEntities: DynamicApplicationSettingValue | null;
	enableDocumentationInformationInApplicationPdf: DynamicApplicationSettingValue | null;
	dyaSettingsMap: Map<DynamicApplicationSettingEnum, DynamicApplicationSettingValue>;
}

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => moduleActionContext(context, InstitutionSettingsModule);

const InstitutionSettingsModule = defineModule({
	namespaced: true,
	// eslint-disable-next-line max-lines-per-function
	state: () => {
		return {
			logoutUrl: '',
			sessionLifetimeInMinutes: BANK_CUSTOMER_USER_SESSION_LIFETIME_MINUTES,
			loginMessage: {
				type: OnlinePortalMessage.TypeEnum.SignUpLogin,
				message: ''
			} as OnlinePortalMessage,
			homepageMessage: {
				type: OnlinePortalMessage.TypeEnum.PortalHomepage,
				message: 'This is your personal portal, where you will be able to apply for loans, upload documents, and view requests from our institution.'
			} as OnlinePortalMessage,
			contactMessage: {
				type: OnlinePortalMessage.TypeEnum.LenderContact,
				message: ''
			} as OnlinePortalMessage,
			documentsMessage: {
				type: OnlinePortalMessage.TypeEnum.DocumentsPage,
				message: ''
			} as OnlinePortalMessage,
			submitApplicationPreMessage: {
				type: OnlinePortalMessage.TypeEnum.SubmitApplicationPreSubmittal,
				message: ''
			} as OnlinePortalMessage,
			submitApplicationPostMessage: {
				type: OnlinePortalMessage.TypeEnum.SubmitApplicationPostSubmittal,
				message: ''
			} as OnlinePortalMessage,
			applicationCssElement: null,
			applicationAutoLockSettings: new Map<DynamicApplicationSettingEnum, boolean>([
				[DynamicApplicationSettingEnum.BusinessApplicationsLockApplicationsAfterBorrowerSubmission, false],
				[DynamicApplicationSettingEnum.PersonApplicationsLockApplicationsAfterBorrowerSubmission, false],
				[DynamicApplicationSettingEnum.NonprofitApplicationsLockApplicationsAfterBorrowerSubmission, false],
				[DynamicApplicationSettingEnum.SBaApplicationsLockApplicationsAfterBorrowerSubmission, false],
				[DynamicApplicationSettingEnum.FarmApplicationsLockApplicationsAfterBorrowerSubmission, false]
			]),
			useBorrowerAccessRestrictionsSetting: null,
			allowBorrowerToSendInvitesSetting: null,
			allowBorrowerToAddRemoveLoansSetting: null,
			allowUserToAddRemoveLoansSetting: null,
			autoSavePdfToDocLibOnSubmission: null,
			shouldMatchGeneralDocumentsPageMessage: null,
			messagesPromise: null,
			showEsignLinesSettings: null,
			allowBorrowersToStartApplications: null,
			showRefinanceQuestion: null,
			borrowerIsNotAllowedToAddDeleteEntities: null,
			enableDocumentationInformationInApplicationPdf: null
		} as InstitutionSettingsModuleState;
	},
	mutations: {
		SET_GET_MESSAGES_PROMISE(state, messagesPromise: Promise<CollectionResponseOnlinePortalMessage>) {
			state.messagesPromise = messagesPromise;
		},
		SET_LOGOUT_URL(state, logoutUrl: string) {
			state.logoutUrl = logoutUrl;
		},
		SET_LOGIN_MESSAGE(state, loginMessage: OnlinePortalMessage) {
			state.loginMessage = loginMessage;
		},
		SET_HOMEPAGE_MESSAGE(state, homepageMessage: OnlinePortalMessage) {
			state.homepageMessage = homepageMessage;
		},
		SET_CONTACT_MESSAGE(state, contactMessage: OnlinePortalMessage) {
			state.contactMessage = contactMessage;
		},
		SET_DOCUMENTS_MESSAGE(state, documentsMessage: OnlinePortalMessage) {
			state.documentsMessage = documentsMessage;
		},
		SET_SUBMIT_APPLICATION_PRE_MESSAGE(state, submitApplicationPreMessage: OnlinePortalMessage) {
			state.submitApplicationPreMessage = submitApplicationPreMessage;
		},
		SET_SUBMIT_APPLICATION_POST_MESSAGE(state, submitApplicationPostMessage: OnlinePortalMessage) {
			state.submitApplicationPostMessage = submitApplicationPostMessage;
		},
		SET_APPLICATION_CSS_ELEMENT(state, applicationCssElement: HTMLElement | null) {
			state.applicationCssElement = applicationCssElement;
		},
		SET_SESSION_LIFETIME_MINUTES(state, sessionLifetimeInMinutes: number) {
			state.sessionLifetimeInMinutes = sessionLifetimeInMinutes;
		},
		SET_APPLICATION_AUTO_LOCK_SETTINGS(state, applicationAutoLockSettings: Map<DynamicApplicationSettingValue.DynamicApplicationSettingEnum, boolean>) {
			state.applicationAutoLockSettings = applicationAutoLockSettings;
		},
		SET_USE_BORROWER_ACCESS_RESTRICTIONS(state, useBorrowerAccessLevelRestrictionsSetting: DynamicApplicationSettingValue) {
			state.useBorrowerAccessRestrictionsSetting = useBorrowerAccessLevelRestrictionsSetting;
		},
		SET_ALLOW_BORROWER_TO_SEND_INVITES(state, allowBorrowerToSendInvites: DynamicApplicationSettingValue) {
			state.allowBorrowerToSendInvitesSetting = allowBorrowerToSendInvites;
		},
		SET_ALLOW_BORROWER_TO_ADD_REMOVE_LOANS(state, allowBorrowerToAddRemoveLoans: DynamicApplicationSettingValue) {
			state.allowBorrowerToAddRemoveLoansSetting = allowBorrowerToAddRemoveLoans;
		},
		SET_ALLOW_USER_TO_ADD_REMOVE_LOANS(state, allowUserToAddRemoveLoans: DynamicApplicationSettingValue) {
			state.allowUserToAddRemoveLoansSetting = allowUserToAddRemoveLoans;
		},
		SET_AUTO_SAVE_PDF_TO_DOC_LIB_ON_SUBMISSION(state, autoSavePdfToDocLibOnSubmission: DynamicApplicationSettingValue) {
			state.autoSavePdfToDocLibOnSubmission = autoSavePdfToDocLibOnSubmission;
		},
		SET_SHOULD_MATCH_GENERAL_DOCUMENTS_PAGE_MESSAGE(state, shouldMatchGeneralDocumentsPageMessage: DynamicApplicationSettingValue) {
			state.shouldMatchGeneralDocumentsPageMessage = shouldMatchGeneralDocumentsPageMessage;
		},
		SET_SHOW_ESIGN_LINES_SETTING(state, showEsignLinesSettings: Map<LoanApplication.TypeEnum, boolean>) {
			state.showEsignLinesSettings = showEsignLinesSettings;
		},
		SET_ALLOW_BORROWERS_TO_START_APPLICATIONS(state, allowBorrowersToStartApplications: DynamicApplicationSettingValue) {
			state.allowBorrowersToStartApplications = allowBorrowersToStartApplications;
		},
		SET_USER_IS_NOT_ALLOWED_TO_ADD_DELETE_ENTITIES(state, borrowerIsNotAllowedToAddDeleteEntities: DynamicApplicationSettingValue) {
			state.borrowerIsNotAllowedToAddDeleteEntities = borrowerIsNotAllowedToAddDeleteEntities;
		},
		SET_SHOW_REFINANCE_QUESTION(state, showRefinanceQuestion: DynamicApplicationSettingValue) {
			state.showRefinanceQuestion = showRefinanceQuestion;
		},
		SET_ENABLE_DOCUMENTATION_INFORMATION_IN_APPLICATION_PDF(state, enableDocumentationInformationInApplicationPdf: DynamicApplicationSettingValue) {
			state.enableDocumentationInformationInApplicationPdf = enableDocumentationInformationInApplicationPdf;
		},
		SET_DYNAMIC_APPLICATION_SETTINGS(state, dyaSettingsMap: Map<DynamicApplicationSettingEnum, DynamicApplicationSettingValue>) {
			state.dyaSettingsMap = dyaSettingsMap;
		}
	},
	actions: {
		async fetchLogoutUrl(context): Promise<void> {
			const { commit } = actionContext(context);
			const bankUrlService = AutoFactory.get(OnlinePortalBankUrlsService);
			let currentSubdomain = getOnlinePortalSubdomain();
			let logoutUrl = null;

			try {
				logoutUrl = (await bankUrlService.getInstitutionLogoutUrlBySubdomain(currentSubdomain)).logoutUrl;
			} catch (err) {
				logoutUrl = '';
			}
			commit.SET_LOGOUT_URL(logoutUrl);
		},
		// eslint-disable-next-line max-lines-per-function
		async fetchAllMessages(context): Promise<void> {
			const { commit, state } = actionContext(context);
			const messageService = AutoFactory.get(OnlinePortalMessagesService);
			let loginMessage: OnlinePortalMessage = {
				type: OnlinePortalMessage.TypeEnum.SignUpLogin,
				message: ''
			} as OnlinePortalMessage;
			let homepageMessage: OnlinePortalMessage = {
				type: OnlinePortalMessage.TypeEnum.PortalHomepage,
				message: 'This is your personal portal, where you will be able to apply for loans, upload documents, and view requests from our institution.'
			} as OnlinePortalMessage;
			let contactMessage: OnlinePortalMessage = {
				type: OnlinePortalMessage.TypeEnum.LenderContact,
				message: ''
			} as OnlinePortalMessage;
			let documentsMessage: OnlinePortalMessage = {
				type: OnlinePortalMessage.TypeEnum.DocumentsPage,
				message: ''
			} as OnlinePortalMessage;
			let submitApplicationPreMessage: OnlinePortalMessage = {
				type: OnlinePortalMessage.TypeEnum.SubmitApplicationPreSubmittal,
				message: ''
			} as OnlinePortalMessage;
			let submitApplicationPostMessage: OnlinePortalMessage = {
				type: OnlinePortalMessage.TypeEnum.SubmitApplicationPostSubmittal,
				message: ''
			} as OnlinePortalMessage;
			if (state.messagesPromise) {
				await state.messagesPromise;
				return;
			}
			const messagesPromise = messageService.getPaged();
			commit.SET_GET_MESSAGES_PROMISE(messagesPromise);
			let messages = (await messagesPromise).items;
			if (messages) {
				loginMessage = messages.filter(x => x.type === OnlinePortalMessage.TypeEnum.SignUpLogin)[0] ?? loginMessage;
				homepageMessage = messages.filter(x => x.type === OnlinePortalMessage.TypeEnum.PortalHomepage)[0] ?? homepageMessage;
				contactMessage = messages.filter(x => x.type === OnlinePortalMessage.TypeEnum.LenderContact)[0] ?? contactMessage;
				documentsMessage = messages.filter(x => x.type === OnlinePortalMessage.TypeEnum.DocumentsPage)[0] ?? documentsMessage;
				submitApplicationPreMessage =
					messages.filter(x => x.type === OnlinePortalMessage.TypeEnum.SubmitApplicationPreSubmittal)[0] ?? submitApplicationPreMessage;
				submitApplicationPostMessage =
					messages.filter(x => x.type === OnlinePortalMessage.TypeEnum.SubmitApplicationPostSubmittal)[0] ?? submitApplicationPostMessage;
			}

			commit.SET_LOGIN_MESSAGE(loginMessage);
			commit.SET_HOMEPAGE_MESSAGE(homepageMessage);
			commit.SET_CONTACT_MESSAGE(contactMessage);
			commit.SET_DOCUMENTS_MESSAGE(documentsMessage);
			commit.SET_SUBMIT_APPLICATION_PRE_MESSAGE(submitApplicationPreMessage);
			commit.SET_SUBMIT_APPLICATION_POST_MESSAGE(submitApplicationPostMessage);
		},
		async fetchLoginMessage(context): Promise<void> {
			const { commit } = actionContext(context);
			const messageService = AutoFactory.get(OnlinePortalMessagesService);
			let loginMessage: OnlinePortalMessage = {
				type: OnlinePortalMessage.TypeEnum.SignUpLogin,
				message: ''
			} as OnlinePortalMessage;

			try {
				let messageResponse = await messageService.getLoginMessageBySubdomain(UrlUtils.getOnlinePortalSubdomain());
				if (messageResponse) {
					loginMessage = messageResponse;
				}
			} catch (err) {
				loginMessage = {
					type: OnlinePortalMessage.TypeEnum.SignUpLogin,
					message: ''
				} as OnlinePortalMessage;
			}

			commit.SET_LOGIN_MESSAGE(loginMessage);
		},
		async fetchAuthorizationSetting(context): Promise<void> {
			const { rootGetters, commit } = actionContext(context);
			const authorizationSettingService = AutoFactory.get(OnlinePortalAuthorizationSettingsService);
			let setting;
			if (rootGetters.User.isLender) {
				setting = await authorizationSettingService.getInstitutionAuthorizationSetting();
			} else {
				setting = await authorizationSettingService.getAuthorizationSetting();
			}
			// sessionLifetimeInMinutes can be 0
			if (!setting.sessionLifetimeInMinutes && setting.sessionLifetimeInMinutes !== 0) {
				setting.sessionLifetimeInMinutes = BANK_CUSTOMER_USER_SESSION_LIFETIME_MINUTES;
			}
			commit.SET_SESSION_LIFETIME_MINUTES(setting.sessionLifetimeInMinutes);
		},
		async fetchApplicationAutoLockSettings(context): Promise<void> {
			const { state, commit } = actionContext(context);
			const autoLockSettingsService = AutoFactory.get(DynamicApplicationAutoLockSettingsService);
			const settings: CollectionResponseDynamicApplicationSettingValue = await autoLockSettingsService.getAll();
			const existingSettings = state.applicationAutoLockSettings;
			settings.items?.forEach((x: DynamicApplicationSettingValue) => {
				if (!x.dynamicApplicationSetting) {
					return;
				}

				// Fixes case-sensitivity enum breakage from bad serialization (DYA-2471)
				if (x.dynamicApplicationSetting.toLowerCase() === 'sbaapplicationslockapplicationsafterborrowersubmission') {
					x.dynamicApplicationSetting = DynamicApplicationSettingEnum.SBaApplicationsLockApplicationsAfterBorrowerSubmission;
				}

				existingSettings.set(x.dynamicApplicationSetting, x.settingValue || false);
			});
			commit.SET_APPLICATION_AUTO_LOCK_SETTINGS(existingSettings);
		},
		async fetchUseBorrowerAccessRestrictions(context, { applicationType }: { applicationType: any }): Promise<void> {
			const { commit, state } = actionContext(context);
			if (state.useBorrowerAccessRestrictionsSetting) {
				return;
			}
			const dynamicApplicationPerApplicationTypeSettingsService = AutoFactory.get(DynamicApplicationPerApplicationTypeSettingsService);
			const useBorrowerAccessRestrictions = await dynamicApplicationPerApplicationTypeSettingsService.getSettingForApplicationType(
				applicationType,
				PerApplicationTypeSettingEnum.UseBorrowerAccessLevelRestrictions
			);
			commit.SET_USE_BORROWER_ACCESS_RESTRICTIONS(useBorrowerAccessRestrictions);
		},
		async fetchAllowBorrowerToSendInvites(context, { applicationType }: { applicationType: any }): Promise<void> {
			const { commit, state } = actionContext(context);
			if (state.allowBorrowerToSendInvitesSetting) {
				return;
			}
			const borrowerAccessRestrictionSettingsService = AutoFactory.get(DynamicApplicationPerApplicationTypeSettingsService);
			const allowBorrowerToSendInvites = await borrowerAccessRestrictionSettingsService.getSettingForApplicationType(
				applicationType,
				PerApplicationTypeSettingEnum.AllowBorrowerToSendInvites
			);
			commit.SET_ALLOW_BORROWER_TO_SEND_INVITES(allowBorrowerToSendInvites);
		},
		async fetchAllowBorrowerToAddRemoveLoans(context, { applicationType }: { applicationType: any }): Promise<void> {
			const { commit, state } = actionContext(context);
			if (state.allowBorrowerToAddRemoveLoansSetting) {
				return;
			}
			const perAppTypeSettingService = AutoFactory.get(DynamicApplicationPerApplicationTypeSettingsService);
			const allowBorrowerToAddRemoveLoans = await perAppTypeSettingService.getSettingForApplicationType(
				applicationType,
				PerApplicationTypeSettingEnum.AllowBorrowerToAddRemoveLoans
			);
			commit.SET_ALLOW_BORROWER_TO_ADD_REMOVE_LOANS(allowBorrowerToAddRemoveLoans);
		},
		async fetchAllowUserToAddRemoveLoans(context, { applicationType }: { applicationType: any }): Promise<void> {
			const { commit, state } = actionContext(context);
			if (state.allowUserToAddRemoveLoansSetting || applicationType === LoanApplication.TypeEnum.Sba) {
				return;
			}
			const perAppTypeSettingService = AutoFactory.get(DynamicApplicationPerApplicationTypeSettingsService);
			const allowUserToAddRemoveLoans = await perAppTypeSettingService.getSettingForApplicationType(
				applicationType,
				PerApplicationTypeSettingEnum.AllowUserToAddRemoveLoans
			);
			commit.SET_ALLOW_USER_TO_ADD_REMOVE_LOANS(allowUserToAddRemoveLoans);
		},
		async fetchAutoSavePdfToDocLibOnSubmission(context, { applicationType }: { applicationType: any }): Promise<void> {
			const { commit, state } = actionContext(context);
			if (state.autoSavePdfToDocLibOnSubmission) {
				return;
			}
			const perAppTypeSettingService = AutoFactory.get(DynamicApplicationPerApplicationTypeSettingsService);
			const autoSavePdfToDocLibOnSubmission = await perAppTypeSettingService.getSettingForApplicationType(
				applicationType,
				PerApplicationTypeSettingEnum.AutoSavePdfToDocLibOnSubmission
			);
			commit.SET_AUTO_SAVE_PDF_TO_DOC_LIB_ON_SUBMISSION(autoSavePdfToDocLibOnSubmission);
		},
		async fetchShouldMatchGeneralDocumentsPageMessage(context, { applicationType }: { applicationType: any }): Promise<void> {
			const { commit, state } = actionContext(context);
			if (state.shouldMatchGeneralDocumentsPageMessage) {
				return;
			}
			const perAppTypeSettingService = AutoFactory.get(DynamicApplicationPerApplicationTypeSettingsService);
			const shouldMatchGeneralDocumentsPageMessage = await perAppTypeSettingService.getSettingForApplicationType(
				applicationType,
				PerApplicationTypeSettingEnum.ShouldMatchGeneralDocumentsPageMessage
			);
			commit.SET_SHOULD_MATCH_GENERAL_DOCUMENTS_PAGE_MESSAGE(shouldMatchGeneralDocumentsPageMessage);
		},
		async fetchShowEsignLinesSettings(context): Promise<void> {
			const { commit } = actionContext(context);
			const showEsignLinesSettingsService = AutoFactory.get(DynamicApplicationEsignatureLinesSettingsService);

			const settings = (await showEsignLinesSettingsService.getAll()).items;
			const settingByAppType = new Map<LoanApplication.TypeEnum, boolean>();
			settings?.forEach(setting => {
				// Workaround for the sba serialization issue
				if (
					setting.dynamicApplicationSetting?.toLocaleLowerCase() ===
					DynamicApplicationSettingValue.DynamicApplicationSettingEnum.SBaApplicationsShowEsignatureLines.toLowerCase()
				) {
					settingByAppType.set(LoanApplication.TypeEnum.Sba, setting.settingValue ?? true);
					return;
				}

				switch (setting.dynamicApplicationSetting) {
					case DynamicApplicationSettingValue.DynamicApplicationSettingEnum.BusinessApplicationsShowEsignatureLines:
						settingByAppType.set(LoanApplication.TypeEnum.Business, setting.settingValue ?? true);
						break;
					case DynamicApplicationSettingValue.DynamicApplicationSettingEnum.PersonApplicationsShowEsignatureLines:
						settingByAppType.set(LoanApplication.TypeEnum.Personal, setting.settingValue ?? true);
						break;
					case DynamicApplicationSettingValue.DynamicApplicationSettingEnum.NonprofitApplicationsShowEsignatureLines:
						settingByAppType.set(LoanApplication.TypeEnum.Nonprofit, setting.settingValue ?? true);
						break;
					case DynamicApplicationSettingValue.DynamicApplicationSettingEnum.FarmApplicationsShowEsignatureLines:
						settingByAppType.set(LoanApplication.TypeEnum.Farm, setting.settingValue ?? true);
						break;
				}
			});

			commit.SET_SHOW_ESIGN_LINES_SETTING(settingByAppType);
		},
		async fetchAllowBorrowersToStartApplications(context): Promise<void> {
			const { commit, state } = actionContext(context);
			if (state.allowBorrowersToStartApplications) {
				return;
			}
			const settingsService = AutoFactory.get(DynamicApplicationSettingsService);
			const setting = DynamicApplicationSettingEnum.AllowBorrowersToStartApplications;
			const settings = (await settingsService.get([setting])).items ?? [];

			// Moved the object creation here to avoid a linting issue (mixed spaces and tabs) that the
			// file save kept inserting the way it was coded before.
			const dynamicApplicationSettingValue = {
				dynamicApplicationSetting: setting,
				settingValue: false
			} as DynamicApplicationSettingValue;

			const allowBorrowersToStartApplications = settings.length > 0 ? settings[0] : dynamicApplicationSettingValue;

			commit.SET_ALLOW_BORROWERS_TO_START_APPLICATIONS(allowBorrowersToStartApplications);
		},
		async fetchBorrowerIsNotAllowedToAddDeleteEntities(context): Promise<void> {
			const { commit, state } = actionContext(context);
			if (state.borrowerIsNotAllowedToAddDeleteEntities) {
				return;
			}

			const setting = DynamicApplicationSettingEnum.BorrowerIsNotAllowedToAddDeleteEntities;
			const dynamicApplicationSettingValue = {
				dynamicApplicationSetting: setting,
				settingValue: false
			} as DynamicApplicationSettingValue;

			const settingsService = AutoFactory.get(DynamicApplicationSettingsService);
			const settings = (await settingsService.get([setting])).items ?? [dynamicApplicationSettingValue];

			commit.SET_USER_IS_NOT_ALLOWED_TO_ADD_DELETE_ENTITIES(settings[0]);
		},
		async fetchShowRefinanceQuestion(context, { applicationType }: { applicationType: any }): Promise<void> {
			const { commit, state } = actionContext(context);
			if (state.showRefinanceQuestion) {
				return;
			}
			const settingsService = AutoFactory.get(DynamicApplicationPerApplicationTypeSettingsService);
			const showRefinanceQuestion = await settingsService.getSettingForApplicationType(
				applicationType,
				PerApplicationTypeSettingEnum.AllowStartingWithRefi
			);
			commit.SET_SHOW_REFINANCE_QUESTION(showRefinanceQuestion);
		},
		async fetchEnableDocumentInformationInApplicationPdf(context): Promise<void> {
			const { commit } = actionContext(context);
			const setting = DynamicApplicationSettingEnum.EnableDocumentInformationInApplicationPdf;
			const dynamicApplicationSettingValue = {
				dynamicApplicationSetting: setting,
				settingValue: false
			} as DynamicApplicationSettingValue;

			const settingsService = AutoFactory.get(DynamicApplicationSettingsService);
			const settings = (await settingsService.get([setting])).items ?? [dynamicApplicationSettingValue];

			commit.SET_ENABLE_DOCUMENTATION_INFORMATION_IN_APPLICATION_PDF(settings[0]);
		},
		async fetchDynamicApplicationSettings(context): Promise<void> {
			const { commit, state } = actionContext(context);

			if (state.dyaSettingsMap) {
				return;
			}

			const settingsService = AutoFactory.get(DynamicApplicationSettingsService);
			const settingsToFetch = [
				DynamicApplicationSettingEnum.ShowLoanStatusInBorrowerPortal,
				DynamicApplicationSettingEnum.ShowExistingEntitiesOnDynamicLoanApplication,
				DynamicApplicationSettingEnum.AllowBorrowerFillOut1071DemographicFormForAllOwners,
				DynamicApplicationSettingEnum.AllowBorrowerSendOut1071DemographicForm
			];

			const response = await settingsService.get(settingsToFetch);
			const { items = [] } = response;
			const dyaSettingsMap = new Map(items.map(x => [x.dynamicApplicationSetting as DynamicApplicationSettingEnum, x]));
			commit.SET_DYNAMIC_APPLICATION_SETTINGS(dyaSettingsMap);
		}
	},
	getters: {
		sessionLifetime(state): number {
			return state.sessionLifetimeInMinutes * ONE_MINUTE_IN_MS;
		},
		shouldAutoLockOnSubmit: (...args) => (appType: LoanApplication.TypeEnum) => {
			const { state } = moduleGetterContext(args, InstitutionSettingsModule);

			switch (appType) {
				case LoanApplication.TypeEnum.Personal:
					return state.applicationAutoLockSettings.get(DynamicApplicationSettingEnum.PersonApplicationsLockApplicationsAfterBorrowerSubmission);
				case LoanApplication.TypeEnum.Business:
					return state.applicationAutoLockSettings.get(DynamicApplicationSettingEnum.BusinessApplicationsLockApplicationsAfterBorrowerSubmission);
				case LoanApplication.TypeEnum.Nonprofit:
					return state.applicationAutoLockSettings.get(DynamicApplicationSettingEnum.NonprofitApplicationsLockApplicationsAfterBorrowerSubmission);
				case LoanApplication.TypeEnum.Sba:
					return state.applicationAutoLockSettings.get(DynamicApplicationSettingEnum.SBaApplicationsLockApplicationsAfterBorrowerSubmission);
				case LoanApplication.TypeEnum.Farm:
					return state.applicationAutoLockSettings.get(DynamicApplicationSettingEnum.FarmApplicationsLockApplicationsAfterBorrowerSubmission);
			}
		},
		useBorrowerAccessRestrictions(state): boolean {
			return state.useBorrowerAccessRestrictionsSetting?.settingValue ?? false;
		},
		allowBorrowerToSendInvites(state): boolean {
			return state.allowBorrowerToSendInvitesSetting?.settingValue ?? false;
		},
		allowBorrowerToAddRemoveLoans(state): boolean {
			return state.allowBorrowerToAddRemoveLoansSetting?.settingValue ?? false;
		},
		allowUserToAddRemoveLoans(state): boolean {
			return state.allowUserToAddRemoveLoansSetting?.settingValue ?? false;
		},
		autoSavePdfToDocLibOnSubmission(state): boolean {
			return state.autoSavePdfToDocLibOnSubmission?.settingValue ?? false;
		},
		shouldMatchGeneralDocumentsPageMessage(state): boolean {
			return state.shouldMatchGeneralDocumentsPageMessage?.settingValue ?? true;
		},
		showLoanStatusInBorrowerPortal(state): boolean {
			const setting = state.dyaSettingsMap.get(DynamicApplicationSettingEnum.ShowLoanStatusInBorrowerPortal);

			return setting?.settingValue ?? false;
		},
		allowBorrowerFillOut1071DemographicFormForAllOwners(state): boolean {
			const setting = state.dyaSettingsMap.get(DynamicApplicationSettingEnum.AllowBorrowerFillOut1071DemographicFormForAllOwners);

			return setting?.settingValue ?? false;
		},
		allowBorrowerSendOut1071DemographicForm(state): boolean {
			const setting = state.dyaSettingsMap.get(DynamicApplicationSettingEnum.AllowBorrowerSendOut1071DemographicForm);

			return setting?.settingValue ?? false;
		},
		showExistingEntitiesOnDynamicLoanApplication(state): boolean {
			const setting = state.dyaSettingsMap.get(DynamicApplicationSettingEnum.ShowExistingEntitiesOnDynamicLoanApplication);
			return setting?.settingValue ?? false;
		},
		borrowerIsNotAllowedToAddDeleteEntities(state): boolean {
			return state.borrowerIsNotAllowedToAddDeleteEntities?.settingValue ?? false;
		}
	}
});

export default InstitutionSettingsModule;
