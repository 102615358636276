import { RelatedLoanRole } from '@sageworks/jpi';
import { CustomComponentType, LoanRoleType } from '@sageworks/dynamic-forms';

/* 
	This is strongly tied to logic from LoanRoleModelConverter in the JPI
	where we are manually assigning role types. If that logic changes it 
	will break this helper.
*/
export function ConvertParentLoanRoleType(loanRoleType: LoanRoleType | undefined): RelatedLoanRole.ParentLoanRoleTypeEnum {
	switch (loanRoleType) {
		case LoanRoleType.CoBorrower:
			return RelatedLoanRole.ParentLoanRoleTypeEnum.CoBorrower;
		case LoanRoleType.Guarantor:
			return RelatedLoanRole.ParentLoanRoleTypeEnum.Guarantor;
		default:
			return RelatedLoanRole.ParentLoanRoleTypeEnum.Other;
	}
}
export function ConvertCustomComponentTypeToLoanRoleType(module: CustomComponentType): LoanRoleType {
	switch (module) {
		case CustomComponentType.coBorrowerEntityVue:
			return LoanRoleType.CoBorrower;
		case CustomComponentType.guarantorEntityVue:
			return LoanRoleType.Guarantor;
		case CustomComponentType.primaryBorrowerEntityVue:
			return LoanRoleType.PrimaryBorrower;
		case CustomComponentType.coSignerEntityVue:
			return LoanRoleType.CoSigner;
		case CustomComponentType.creditApplicantEntityVue:
			return LoanRoleType.CreditApplicant;
		default:
			throw new Error(`Module type ${module} is not a valid loan role type`);
	}
}
