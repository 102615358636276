import { AggregatedDemographicInformation } from '@/models/aggregated-demographic-information';
import {
	AutoFactory,
	Demographic1071Summary,
	DynamicApplicationGMIFormLink,
	HMDAGMIService,
	OnlinePortalDemographicInformationService,
	OnlinePortalRegulation1071DemographicDataStatusService,
	OnlinePortalRegulation1071EmailService
} from '@sageworks/jpi';
import { defineModule, localActionContext } from 'direct-vuex';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, DemographicFormModule);

export interface DemographicInformationFormModuleState {
	demographic1071Summaries: Demographic1071Summary[];
	demographic1071FormsStatus: boolean | null;
	gmiFormLinks: DynamicApplicationGMIFormLink[];
	allHmdaGmiFormsValidForSubmit: boolean | null;
}

const DemographicFormModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			demographic1071Summaries: [],
			demographic1071FormsStatus: null,
			gmiFormLinks: [],
			allHmdaGmiFormsValidForSubmit: null
		} as DemographicInformationFormModuleState;
	},
	mutations: {
		SET_DEMOGRAPHIC_1071_SUMMARIES(state, demographic1071Summaries: Demographic1071Summary[]) {
			state.demographic1071Summaries = demographic1071Summaries;
		},
		SET_STATUS_FOR_All_FORMS(state, demographic1071FormsStatus: boolean) {
			state.demographic1071FormsStatus = demographic1071FormsStatus;
		},
		SET_GMI_FORM_LINKS(state, gmiFormLinks: DynamicApplicationGMIFormLink[]) {
			state.gmiFormLinks = gmiFormLinks;
		},
		SET_ALL_HMDA_GMI_FORMS_VALID_FOR_SUBMIT(state, allValid: boolean | null) {
			state.allHmdaGmiFormsValidForSubmit = allValid;
		}
	},
	actions: {
		async loadData(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { commit } = actionContext(context);
			const service = AutoFactory.get(OnlinePortalDemographicInformationService);

			const demographicData = await service.getDemographicInformation(loanApplicationId);
			commit.SET_DEMOGRAPHIC_1071_SUMMARIES(demographicData.demographicData ?? []);
			commit.SET_GMI_FORM_LINKS(demographicData.gmiFormLinks ?? []);
		},

		/**
		 * 1071 form actions
		 */

		async reload1071FormStatus(context, { proposedLoanId, customerId }: { proposedLoanId: number; customerId: number }) {
			const { commit, state } = actionContext(context);
			const { demographic1071Summaries } = state;

			const index = demographic1071Summaries
				.findIndex((summary) => summary.customerId === customerId && summary.proposedLoanId === proposedLoanId);

			// No point in updating the status if we don't have the 1071 form summmary
			if (index < 0) {
				return;
			}

			// Fetch the status
			const statusService = AutoFactory.get(OnlinePortalRegulation1071DemographicDataStatusService);
			const status = await statusService.getStatusForProfitEntityOnProposedLoan(proposedLoanId, customerId) as Demographic1071Summary.StatusEnum;

			// Generate a new summary list with the updated status
			const newSummaries = demographic1071Summaries.map<Demographic1071Summary>((summary, curIndex) => ({
				...summary,
				// Only update the status for the customer we are interested in
				status: curIndex === index ? status : summary.status,
			}));

			commit.SET_DEMOGRAPHIC_1071_SUMMARIES(newSummaries);
		},
		async load1071FormLogs(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { commit, state } = actionContext(context);

			// Fetch the latest email logs
			const logsService = AutoFactory.get(OnlinePortalRegulation1071EmailService);
			const logs = await logsService.getLogs(loanApplicationId);

			// Update the summaries with the email logs
			const { demographic1071Summaries } = state;
			const newSummaries = demographic1071Summaries.map<Demographic1071Summary>((summary) => ({
				...summary,
				emailRequestLog: logs.filter((log) => log.customerId === summary.customerId),
			}));

			commit.SET_DEMOGRAPHIC_1071_SUMMARIES(newSummaries);
		},
		async loadAll1071FormsValidForSubmit(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { commit } = actionContext(context);
			const logsService = AutoFactory.get(OnlinePortalRegulation1071DemographicDataStatusService);
			const demographic1071FormsStatus = await logsService.demographic1071FormsAreComplete(loanApplicationId);
			commit.SET_STATUS_FOR_All_FORMS(demographic1071FormsStatus);
		},

		/**
		 * HMDA GMI form actions
		 */

		async toggleGmiWaiveForm(context, gmiFormLink: DynamicApplicationGMIFormLink) {
			const { commit, state } = actionContext(context);

			// Waive/Unwaive the form
			const gmiService = AutoFactory.get(HMDAGMIService);
			const updatedForm = await gmiService.toggleWaiveForm(gmiFormLink);


			// Update the form in the store
			const { gmiFormLinks } = state;
			const newGmiFormLinks = gmiFormLinks.map<DynamicApplicationGMIFormLink>((link) => ({
				// only overwrite the form we are interested in
				...link.id === updatedForm.id ? updatedForm : link,
			}));

			commit.SET_GMI_FORM_LINKS(newGmiFormLinks);
		},
		async loadGmiForms(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { commit } = actionContext(context);

			const gmiService = AutoFactory.get(HMDAGMIService);

			const gmiFormLinks = await gmiService.getGmiFormLinks(loanApplicationId);
			commit.SET_GMI_FORM_LINKS(gmiFormLinks);
		},
		async loadAllHmdaGmiFormsValidForSubmit(context, loanApplicationId) {
			const { commit } = actionContext(context);

			const gmiService = AutoFactory.get(HMDAGMIService);

			const formsAreComplete = await gmiService.formsAreComplete(loanApplicationId);
			commit.SET_ALL_HMDA_GMI_FORMS_VALID_FOR_SUBMIT(formsAreComplete);
		}
	},
	getters: {
		demographicInformationByCustomers: ({ demographic1071Summaries, gmiFormLinks }): AggregatedDemographicInformation[] => {
			// Get All Customer Ids so we can create an entry for each customer
			const customerIds = new Set<number>([
				...demographic1071Summaries.map((summary) => summary.customerId!),
				...gmiFormLinks.map((link) => link.customerId!)
			]);

			// Create an entry for each customer
			return Array.from(customerIds).map<AggregatedDemographicInformation>((customerId) => {
				return {
					customerId,
					demographic1071Summaries: demographic1071Summaries.filter((summary) => summary.customerId === customerId),
					gmiFormLinks: gmiFormLinks.filter((link) => link.customerId === customerId),
				};
			});
		},
	}
});

export default DemographicFormModule;
