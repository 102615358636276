import { DynamicFormsHookType } from '../../../../enums';
import Component from 'formiojs/components/_classes/component/Component';
import { configureLinkBehavior, configureLinkTemplate } from '../../base/edit-link-helper';
import { isInPreviewMode } from '../../../../utils/formio-component-utils';
import { BaseRoleRepeaterWidget } from '../base-role-repeater-widget/base-role-repeater-widget';
import { Customer } from '@sageworks/jpi';
import { addBlankNewRoleForType, buildExistingCustomerResult } from '../../../../utils/primary-role-utils/primary-role-add-row-utils';
import { convertCustomerTypeToEntityType } from '../../../../utils/customer-type-utils/customer-type-utils';
import { AddCustomerResult } from '../../add-customer';
import NestedComponent from 'formiojs/components/_classes/nested/NestedComponent';

enum ComponentState {
	ListView,
	EditView
}

export default class VueFormioDataListComponent extends BaseRoleRepeaterWidget {
	static schema(...extend: any): any {
		return Component.schema({}, ...extend);
	}

	get isInPreviewMode() {
		return isInPreviewMode(this);
	}

	public vueComponent: any;
	protected state = ComponentState.ListView;
	protected vueContainerRef = 'vue-container';

	constructor(component: any, options: any, data: object) {
		super(component, options, data);

		const hookParams = { componentType: this.component.type, schema: this.component };
		const hookResponse = this.hook(DynamicFormsHookType.CreateVueComponent, hookParams);

		// Ensure the response is not null or the parameters passed in (for some reason, formio returns back the params if it can't find a matching hook)
		if (hookResponse != null && hookResponse !== hookParams) {
			this.vueComponent = hookResponse;
		}

		// add-existing-entity is used to add an existing entity to the list - we have to load the details and avoid popping the edit screen
		this.vueComponent.$on('add-existing-entity', (entityData: any) => this.addEntity(entityData.dataObjectType, entityData.customerId));
		this.vueComponent.$on('add-entity', (entity: Customer.TypeEnum) => this.addEntity(entity, -1));
		this.vueComponent.$on('edit-entity', (id: number) => this.editRow(id));
		this.vueComponent.$on('remove-entity', (id: number) => this.removeRow(id));
	}

	async attach(element: HTMLElement) {
		if (this.builderMode) {
			configureLinkBehavior(this, element);
			return;
		} else if (isInPreviewMode(this)) {
			return;
		}
		// we did this to by pass the logic ran in EditGrid since it creates event listeners that we no longer need
		await NestedComponent.prototype.attach.call(this, element);

		this.loadRefs(element, {
			[this.rowRef]: 'multiple',
			[this.vueContainerRef]: 'single'
		});

		if (this.state === ComponentState.EditView) {
			for (let rowIndex = 0; rowIndex < this.rowElements.length; rowIndex++) {
				const row = this.rowElements[rowIndex];
				const editRow = this.editRows[rowIndex];

				if (this.isOpen(editRow) && this.state === ComponentState.EditView) {
					await this.attachComponents(row, editRow.components);
				}
			}
		} else if (this.vueComponent && this.state === ComponentState.ListView) {
			await this.preAttachVueComponentSetup();
			((this.refs[this.vueContainerRef] as unknown) as HTMLDivElement).appendChild(this.vueComponent.$el);
		}
	}
	public async beforeSubmit() {
		// this is needed because when we 'save and return' we want to change the element that is being rendered.
		// Setting the state in the save function is not enough since we save when we create a new entity as well.
		this.state = ComponentState.ListView;
		return super.beforeSubmit();
	}

	render(children: any, _topLevel?: boolean): any {
		if (this.builderMode) {
			return configureLinkTemplate(this);
		}

		return super.render(
			children ||
				this.renderTemplate('vueDataList', {
					ref: {
						row: this.rowRef,
						vueContainer: this.vueContainerRef
					},
					state: this.state,
					rows: this.editRows.map(this.renderRow.bind(this)),
					openRows: this.editRows.map(row => this.isOpen(row) && this.state === ComponentState.EditView),
					errors: this.editRows.map(row => row.error)
				})
		);
	}

	checkComponentValidity(data: any, dirty: boolean, row: any, options: any = {}): boolean | Promise<boolean> {
		const superResult = super.checkComponentValidity(data, dirty, row, options);
		const { async = false, silentCheck = false } = options;

		if (async) {
			return (superResult as Promise<boolean>).then(valid => {
				const isVueComponentValid = this.validateVueComponent(dirty, silentCheck);

				return valid && isVueComponentValid;
			});
		}

		return superResult && this.validateVueComponent(dirty, silentCheck);
	}

	addMessages(_messages: any) {
		// Overwriting existing implementation and leaving empty since we don't want formio to show the validation errors b/c they are being
		// 	controlled by the vue component
	}

	protected validateVueComponent(dirty: boolean, silentCheck: boolean): boolean {
		// Run validation within the vue component to get the validation messages
		this.vueComponent?.validate?.();
		const validationMessages = this.vueComponent?.validationMessages ?? [];

		// Set the validation messages so formio can pick them up
		this.setComponentValidity(validationMessages, dirty, silentCheck);

		return validationMessages.length === 0;
	}

	// Add an entity using the profitEntities ID value passed in to the list for the current loan for the entityType
	// passed in (e.g. add a guarantor to the list for the current loan).  We need the id to determine if this is an
	// existing entity or a new one.  If the id is <= 0, we are adding a new entity and we need to pop the edit screen.
	async addEntity(entityType: Customer.TypeEnum, id: number) {
		// if we are adding an existing entity, we need to get the data from the server
		// and flag calls downstream that we are existing so we do not pop an edit screen
		let data = null;
		const addingExistingEntity = id > 0;

		// If we are adding a new entity that the user will need to fill in (pop up the edit screen)
		if (!addingExistingEntity) {
			data = await addBlankNewRoleForType(this, convertCustomerTypeToEntityType(entityType));
		} else {
			const newCustomer: Customer = {
				id: id,
				type: entityType,
				addresses: []
			};
			data = await buildExistingCustomerResult(this, newCustomer);
		}

		this.addRow();
		const rowIndex = this.editRows.length - 1;
		await this.saveEntity(rowIndex, data, addingExistingEntity);
	}

	protected async saveEntity(_rowIndex: number, _data: AddCustomerResult, _addingExistingEntity: boolean) {
		throw new Error('Not Implemented');
	}

	// Override - We do not care about the loading spinner logic
	protected async partialSaveRow(rowIndex: number) {
		await this.partialSave(rowIndex);
	}

	saveRow(rowIndex: number, modified?: boolean) {
		this.state = ComponentState.ListView;

		const result = super.saveRow(rowIndex, modified);
		if (result) {
			this.emitEditEndEvent();
		}

		return result;
	}

	async removeRow(rowIndex: number) {
		this.state = ComponentState.ListView;

		const result: any = super.removeRow(rowIndex);
		this.emitEditEndEvent();

		return result;
	}

	async editRow(rowIndex: number) {
		this.state = ComponentState.EditView;
		const result: any = super.editRow(rowIndex);
		this.emitEditEvent();

		return result;
	}

	cancelRow(rowIndex: number) {
		const editRow = this.editRows[rowIndex];
		const originalState = editRow.state;

		const result = super.cancelRow(rowIndex);

		if (originalState !== editRow.state) {
			this.emitEditEndEvent();
		}

		return result;
	}

	protected async preAttachVueComponentSetup() {
		// this method has been intentionally been left blank so we can access functionality on primary-role-repeater-widget-vue
	}
}
