
import { Component, Mixins } from 'vue-property-decorator';
import { RouteMixin } from '@/mixins';
import { IdentityVerificationHeader } from '@/components';
import { getFailureOptions } from '@sageworks/dynamic-forms/src/utils/toastr-utils';
import { UrlUtils } from '@/utils';
import { AddPersonaSnippetScript } from '@/utils/persona-utils';

@Component({
	components: { IdentityVerificationHeader }
})
export default class IdentityProtection extends Mixins(RouteMixin) {
	institutionName = '';
	newAccount = true;
	isLoading: boolean = false;
	submitting: boolean = false;
	rememberThisDevice: boolean = false;
	async created() {
		this.newAccount = this.directStore.state.Authentication.newAccount;
		if (this.newAccount) {
			this.institutionName = (await UrlUtils.getOnlinePortalInstitutionFromUrl())?.institutionName ?? '';
		}

		if (!this.personaSettings) {
			this.isLoading = true;
			await this.directStore.dispatch.Persona.fetchPersonaSettings();
			this.isLoading = false;
		}
	}

	get personaSettings() {
		return this.directStore.state.Persona.settings;
	}

	get personaEnabled() {
		return this.personaSettings?.isEnabled ?? false;
	}

	get identityMessage() {
		if (this.personaEnabled) {
			return 'In order to protect your identity you will be guided through an identity verification process with our partner Persona.';
		}
		return "In order to protect your identity, you'll now be asked to enter information about yourself and answer a few questions.";
	}

	async continueVerification() {
		if (this.personaEnabled) {
			await this.openPersonaKYC();
		} else {
			await this.$router.push(this.OnlinePortalRoutes.VerifyIdentity.path);
		}
	}

	async openPersonaKYC(): Promise<void> {
		this.submitting = true;
		await AddPersonaSnippetScript();
		const keys = this.personaSettings.keys;
		const templateId = keys.useSandboxApi ? keys.sandboxTemplateIdExternal : keys.prodTemplateIdExternal;
		const environmentId = keys.useSandboxApi ? keys.sandboxEnvironmentIdExternal : keys.prodEnvironmentIdExternal;
		// eslint-disable-next-line
		const client = new Persona.Client({
			templateId: templateId,
			environmentId: environmentId,
			onReady: () => client.open(),
			onComplete: async ({ inquiryId }) => {
				const verificationPassed = await this.performPersonaVerification(inquiryId);
				if (verificationPassed) {
					await this.loginAndRedirect();
				} else {
					this.showErrorMessage();
					this.submitting = false;
				}
			},
			onCancel: () => {
				this.submitting = false;
			},
			onError: () => {
				this.showErrorMessage();
				this.submitting = false;
			}
		});
	}

	async performPersonaVerification(inquiryId: string): Promise<boolean> {
		try {
			await this.directStore.dispatch.Persona.generatePersonaIdentityToken({ inquiryId: inquiryId, rememberThisDevice: this.rememberThisDevice });
			return true;
		} catch {
			return false;
		}
	}

	showErrorMessage(): void {
		this.$root.$bvToast.toast('We could not verify your identity. Please try again.', getFailureOptions());
	}

	async loginAndRedirect(): Promise<void> {
		await this.directStore.dispatch.Authentication.login({
			username: this.directStore.state.Login.userEmail,
			secret: this.directStore.state.Login.userPassword
		});
		await this.$router.push(this.OnlinePortalRoutes.Home.path);
	}
}
