
import Vue, { PropType } from 'vue';
import BorrowerConfirmModal from '@/components/submit-page/borrower-confirm-modal.vue';

export default Vue.extend({
	name: 'ReviewBorrower',
	components: { BorrowerConfirmModal },
	props: {
		notYetReadyForReview: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		isLenderReview: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		preSubmitMessage: {
			type: String as PropType<string | null>,
			default: null
		},
		isReadyForBorrowerSubmission: {
			type: Boolean as PropType<boolean | null>,
			default: null
		}
	},
	data() {
		return {};
	},
	computed: {},
	mounted() {},
	methods: {
		markReadyForReview() {
			this.$emit('mark-ready-for-review');
		}
	}
});
