
import Vue, { PropType } from 'vue';
import { DynamicApplicationGMIFormLink } from '@sageworks/jpi';

import { formIsComplete } from '@/utils/hmda-gmi-api-helper';
import HmdaGmiForm from '../hmda-gmi-form.vue';
import DemographicForm1071 from './demographic-form-1071.vue';
import { DetailedAggregatedDemographicInformation } from '@/models/aggregated-demographic-information';

export default Vue.extend({
	name: 'DemographicForms',
	components: { DemographicForm1071, HmdaGmiForm },
	props: {
		data: {
			type: Array as PropType<DetailedAggregatedDemographicInformation[]>,
			required: true
		},
		applicationId: {
			type: String as PropType<string | null>,
			default: null
		},
		customers: {
			type: Object as PropType<{ [customerId: string]: string } | null>,
			default: null
		}
	},
	data() {
		return {
			isLoading: true,
			formLinkIntervalId: 0,
			formLinkTimeoutId: 0,
			intervalLength: 2000, // 1000 (1 sec) * 2 = 2000 (2 seconds)
			timeoutLength: 300000 // 6000 (1 min) * 5 = 30000 (5 minutes)
		};
	},
	computed: {
		loanApplicationId(): number {
			if (!this.applicationId) {
				return 0;
			}
			const id = parseInt(this.applicationId, 10);
			return Number.isNaN(id) ? 0 : id;
		}
	},
	methods: {
		formLinkOpened(formLinkInfo: DynamicApplicationGMIFormLink) {
			clearInterval(this.formLinkIntervalId);
			clearTimeout(this.formLinkTimeoutId);

			// Stop checking after 5 minutes, clear interval
			this.formLinkTimeoutId = setTimeout(() => {
				clearInterval(this.formLinkIntervalId);
			}, this.timeoutLength);

			this.formLinkIntervalId = setInterval(() => this.checkForCompleteForm(formLinkInfo), this.intervalLength);
		},
		async checkForCompleteForm(formLinkInfo: DynamicApplicationGMIFormLink) {
			const isComplete = await formIsComplete(formLinkInfo);
			if (isComplete) {
				clearInterval(this.formLinkIntervalId);
				clearTimeout(this.formLinkTimeoutId);
				await this.directStore.dispatch.DemographicForm.loadGmiForms({ loanApplicationId: this.loanApplicationId });
			}
		}
	}
});
