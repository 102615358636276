import { ApplicationValidationStage } from '../../enums';
import { StoreType } from '../../store';
import { FeatureFlagging, Permission, ValidationResult } from '@sageworks/jpi';
import { ApplicationSubmitValidationErrors, ValidationProductDetails } from './application-submit-validation-errors';
import { ApplicationSubmitValidationResults } from './application-submit-validation-results';
import { buildValidationMessages } from './application-validation-message-utils';

export class MultiLoanApplicationValidator {
	public currentStage: ApplicationValidationStage = ApplicationValidationStage.NotValidating;
	public stepResults: ApplicationSubmitValidationResults = {};

	private _applicationValidationResult: { [key: string]: ValidationResult } = {};

	constructor(private _store: StoreType, private _applicationId?: number) {}

	public async validateTermsAndConditions(ignore = false) {
		return ignore ? true : this._store.state.LoanApplicationData.loanApplication?.agreedToTerms ?? false;
	}

	public async validateDocumentRequests() {
		const documentsValid = await this.validateDocumentsForApplication();
		const hmdaValid = await this.validateHmdaGmiForms();
		const demographic1071Valid = await this.validate1071Forms();
		return documentsValid && hmdaValid && demographic1071Valid;
	}

	public async validateDocumentsForApplication() {
		await this._store.dispatch.ApplicationDocumentRequest.loadPagedDocumentRequests({
			page: 1,
			perPage: 1000,
			loanApplicationId: this._applicationId ?? 0
		});

		return this._store.getters.ApplicationDocumentRequest.allRequestsValidForSubmit;
	}

	public async validateHmdaGmiForms() {
		const isHmdaGmiActive = await this._store.dispatch.FeatureFlag.fetchIsFeatureFlagActive(FeatureFlagging.FeatureFlagEnum.EnableHmdainDya);

		if (!isHmdaGmiActive) {
			return true;
		}

		await this._store.dispatch.DemographicForm.loadAllHmdaGmiFormsValidForSubmit(this._applicationId ?? 0);

		return this._store.state.DemographicForm.allHmdaGmiFormsValidForSubmit ?? false;
	}

	public async validate1071Forms() {
		const is1071FormEmailsActive = await this._store.dispatch.FeatureFlag.fetchIsFeatureFlagActive(FeatureFlagging.FeatureFlagEnum.Enable1071FormEmails);
		const isLender = this._store.getters.User.isLender;
		const lenderAllowedToSubmitWithIncomplete1071Forms = this._store.getters
			.LenderAuthorizationModule.canAccessFeature(Permission.ProductFeatureEnum.AbleToSubmitWithIncomplete1071DemographicForm);
		const isUserAllowedToSubmitWithIncomplete1071Forms = isLender && lenderAllowedToSubmitWithIncomplete1071Forms;

		if (!is1071FormEmailsActive || isUserAllowedToSubmitWithIncomplete1071Forms) {
			return true;
		}

		await this._store.dispatch.DemographicForm.loadAll1071FormsValidForSubmit({ loanApplicationId: this._applicationId ?? 0 });

		return this._store.state.DemographicForm.demographic1071FormsStatus ?? false;
	}

	public async validateApplicationSubmitStatus(ignore = false) {
		return ignore ? true : this._store.state.LoanApplicationData.loanApplication?.submittedDate == null;
	}

	public async validateLoanApplication(): Promise<boolean> {
		if (!this._applicationId) {
			return false;
		}

		const { results } = await this._store.dispatch.LoanApplicationData.validateLoanApplication({
			loanApplicationId: this._applicationId
		});

		this._applicationValidationResult = results ?? {};

		return Object.values(results ?? {}).every(x => x.isValid);
	}

	public async validate(
		skipSubmitStatusValidation: boolean = false,
		skipTermsAndConditionsValidation: boolean = false
	): Promise<ApplicationSubmitValidationErrors> {
		try {
			this.stepResults = {};
			this.currentStage = ApplicationValidationStage.NotValidating;
			this._applicationValidationResult = {};

			await this.runAllValidations(skipSubmitStatusValidation, skipTermsAndConditionsValidation);

			return this.buildValidationErrors();
		} catch (err) {
			// eslint-disable-next-line
			console.error(err);
			return { messages: [{ title: 'Unable to Submit', message: 'An error occurred while confirming application data.' }], productsNotValid: [] };
		} finally {
			this.currentStage = ApplicationValidationStage.NotValidating;
		}
	}

	private async runAllValidations(skipSubmitStatusValidation: boolean = false, skipTermsAndConditionsValidation: boolean = false): Promise<void> {
		// step: did user agree to terms?
		this.currentStage = ApplicationValidationStage.TermsAndConditionsCompleted;
		this.stepResults.termsAndConditions = await this.validateTermsAndConditions(skipTermsAndConditionsValidation);
		if (!this.stepResults.termsAndConditions && !this._store.getters.User.isLender) {
			return;
		}

		// step: did user satisfy all document requests?
		this.currentStage = ApplicationValidationStage.DocumentRequestsCompleted;
		this.stepResults.documentRequestsValid = await this.validateDocumentRequests();
		if (!this.stepResults.documentRequestsValid) {
			return;
		}

		// step: does the application entity appear to be submittable?
		this.currentStage = ApplicationValidationStage.ApplicationSubmittable;
		this.stepResults.canBeSubmitted = await this.validateApplicationSubmitStatus(skipSubmitStatusValidation);
		if (!this.stepResults.canBeSubmitted) {
			return;
		}

		this.stepResults.formDataValid = await this.validateLoanApplication();
	}

	private buildValidationErrors(): ApplicationSubmitValidationErrors {
		return {
			messages: buildValidationMessages(this.stepResults, this._store.getters.User.isLender),
			productsNotValid: this.getProductsNotValid(this._applicationValidationResult)
		};
	}

	private getProductsNotValid(appValidationResult: { [key: string]: ValidationResult }) {
		const uniqueNameLookup = new Set<string>();

		const products = Object.keys(appValidationResult)
			.filter(loanMappingId => !this._applicationValidationResult[loanMappingId].isValid)
			.map(loanMappingId => this.createProductDetails(loanMappingId, uniqueNameLookup))
			.filter((x): x is ValidationProductDetails => !!x);

		this.adjustProductNameBasedOnDuplicates(products, uniqueNameLookup);

		return products;
	}

	private createProductDetails(loanMappingId: string, uniqueNameLookup: Set<string>): ValidationProductDetails | undefined {
		const { metadata } = this._store.state.LoanApplicationMetadata;
		const index = metadata.findIndex(x => x.loanMappingId === Number(loanMappingId));

		if (index < 0) return undefined;

		const product = metadata[index].proposedProduct;
		let name = product?.name ?? '';

		if (uniqueNameLookup.has(name)) {
			uniqueNameLookup.delete(name);
		} else {
			uniqueNameLookup.add(name);
		}

		return { index, name };
	}

	private adjustProductNameBasedOnDuplicates(products: ValidationProductDetails[], uniqueNameLookup: Set<string>) {
		const nameCounters = new Map<string, number>();

		for (const product of products) {
			const { name } = product;

			if (uniqueNameLookup.has(name)) {
				continue;
			}

			const count = (nameCounters.get(name) ?? 0) + 1;
			product.name = `${name} (${count})`;
			nameCounters.set(name, count);
		}
	}
}
