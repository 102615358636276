
import Vue, { PropType } from 'vue';
import { LoanRoleType } from '@sageworks/dynamic-forms';
import { ApplicationFormPdfCssStyleUtils } from '@/utils';
import { ComponentState } from '@/enums';
import { LoanApplication, LoanOfficer } from '@sageworks/jpi';
import { ApplicationPdf } from '../../../components/application-pdf';
import { ApplicationSpinner } from '@/components';
import { AutoFactory, DocumentLibrarySavePdfFromHtmlRequest, OnlinePortalLoanApplicationsService } from '../../../../../JPI';
import { SaveToDocLibraryModal } from '@/components/lender-features/save-to-doc-library-modal';
import { Permission, LoanRelationship } from '@sageworks/jpi';
import ProductFeatureEnum = Permission.ProductFeatureEnum;
import { getSuccessOptions, getFailureOptions } from '@sageworks/dynamic-forms/src/utils/toastr-utils';

export default Vue.extend({
	name: 'ApplicationExport',
	components: {
		ApplicationSpinner,
		ApplicationPdf,
		SaveToDocLibraryModal
	},
	props: {
		applicationId: { type: String as PropType<string> },
		lenderOnlyMode: { type: Boolean, default: true },
		forceExportState: { type: Boolean, default: false },
		messageChannel: { type: String, default: '' }
	},
	data() {
		return {
			componentState: ComponentState.INIT,
			enableExport: false as Boolean,
			LoanRoleType
		};
	},
	computed: {
		applicationIdAsNumber(): number {
			const id = Number.parseInt(this.applicationId, 10);
			if (Number.isNaN(id)) {
				throw Error('Could not parse application id');
			}

			return id;
		},
		loanApplication(): LoanApplication | null {
			return this.directStore.state.LoanApplicationData.loanApplication;
		},
		applicationNumber(): number | null {
			return this.loanApplication?.applicationNumber || null;
		},
		logoUrl(): string {
			return this.directStore.getters.StylingSettings.logoUrl;
		},
		renderData() {
			return this.directStore.state.PDFPreviewModule.renderData;
		},
		loanOfficer(): LoanOfficer | null {
			return this.directStore.state.LoanApplicationData.loanOfficer;
		},
		showFormSpinner(): boolean {
			return [ComponentState.INIT, ComponentState.LOADING, ComponentState.EXPORTING].includes(this.componentState);
		},
		showForm(): boolean {
			return [ComponentState.LOADED, ComponentState.EXPORTING].includes(this.componentState);
		},
		showExportButton(): boolean {
			return this.componentState === ComponentState.LOADED;
		},
		spinnerLabel() {
			switch (this.componentState) {
				case ComponentState.EXPORTING:
					return 'Exporting application to PDF';
				default:
					return 'Preparing application';
			}
		},
		showSaveToDocLibButton(): boolean {
			return this.directStore.getters.User.isLender;
		},
		enableSaveToDocLibButton(): boolean {
			return this.directStore.getters.LenderAuthorizationModule.canAccessFeature(ProductFeatureEnum.UploadDocuments);
		},
		isExporting(): boolean {
			return this.componentState === ComponentState.EXPORTING;
		},
		showEsignLines(): boolean {
			if (this.directStore.state.AdobeESignModule.customerCanUseEsign) {
				return true;
			}

			const esignLinesSetting = this.directStore.state.InstitutionSettings.showEsignLinesSettings;
			const loanApplicationType = this.loanApplication?.type;
			return esignLinesSetting?.get(loanApplicationType!) ?? true;
		},
		loanRelationships(): (LoanRelationship | null)[] {
			return this.directStore.state.PDFPreviewModule.loanRelationships;
		},
		showRefinanceQuestion(): boolean {
			return this.directStore.state.InstitutionSettings.showRefinanceQuestion?.settingValue ?? false;
		},
		showDocumentationSection(): boolean {
			return this.directStore.state.InstitutionSettings.enableDocumentationInformationInApplicationPdf?.settingValue ?? false;
		}
	},
	watch: {
		showExportButton(newShowExportButton: boolean) {
			// We only need to message the parent window if a message channel is set in the query params
			if (newShowExportButton && this.messageChannel) {
				this.$root.$emit('parent-window-message', { payload: { isLoaded: true }, channel: this.messageChannel });
			}
		}
	},
	async mounted(): Promise<void> {
		this.loadInitialData();
	},
	methods: {
		loadInitialData: async function() {
			try {
				this.componentState = ComponentState.LOADING;

				await Promise.all([
					this.directStore.dispatch.LoanApplicationData.loadLoanApplicationBundle({ loanApplicationId: this.applicationIdAsNumber }),
					this.directStore.dispatch.PDFPreviewModule.loadPdfData(this.applicationIdAsNumber as number),
					this.directStore.dispatch.LenderAuthorizationModule.loadUserPermissions(),
					this.directStore.dispatch.InstitutionSettings.fetchShowEsignLinesSettings()
				]);

				if (!this.loanApplication?.type) {
					throw new Error('loan application was not loaded');
				}

				await Promise.all([
					this.directStore.dispatch.AdobeESignModule.getUserCanUseEsign(this.loanApplication.type),
					this.directStore.dispatch.InstitutionSettings.fetchShowRefinanceQuestion({ applicationType: this.loanApplication.type }),
					this.directStore.dispatch.InstitutionSettings.fetchEnableDocumentInformationInApplicationPdf(),
				]);

				this.componentState = ComponentState.LOADED;
			} catch (err) {
				this.componentState = ComponentState.ERROR;
				throw err;
			}
		},
		async loadPdfContent() {
			// Wait one tick in order to allow the image logo to show in the HTML element
			await this.$nextTick();

			const htmlContent = (this.$refs['pdf'] as Vue).$el?.innerHTML || '';
			const stylesContent = ApplicationFormPdfCssStyleUtils.retrieveApplicationFormPdfStyles();
			return '<html><head><meta charset="utf-8"/>' + stylesContent + '</head><body>' + htmlContent + '</body></html>';
		},
		async exportToPdf() {
			this.componentState = ComponentState.EXPORTING;

			const html = await this.loadPdfContent();

			await this.directStore.dispatch.AdobeESignModule.exportApplicationToPdf({ loanApplicationNumber: this.applicationIdAsNumber, html });
			this.componentState = ComponentState.LOADED;
		},
		async saveToDocLibrary(options: DocumentLibrarySavePdfFromHtmlRequest) {
			this.componentState = ComponentState.EXPORTING;
			this.closeSaveToDocLibraryModal();

			const request = {
				html: await this.loadPdfContent(),
				customOptions: options
			} as DocumentLibrarySavePdfFromHtmlRequest;

			try {
				await AutoFactory.get(OnlinePortalLoanApplicationsService).saveApplicationPdfToDocumentLibrary(
					this.applicationIdAsNumber,
					request as DocumentLibrarySavePdfFromHtmlRequest
				);
				this.$root.$bvToast.toast('PDF saved successfully', getSuccessOptions());
			} catch {
				this.$root.$bvToast.toast('Error saving PDF to Document Library', getFailureOptions());
			}

			this.componentState = ComponentState.LOADED;
		},
		async openSaveToDocLibraryModal() {
			this.$root.$bvModal.show('save-to-doc-library-modal');
		},
		async closeSaveToDocLibraryModal() {
			this.$nextTick(() => {
				this.$root.$bvModal.hide('save-to-doc-library-modal');
			});
		}
	}
});
