
import Vue, { PropType } from 'vue';

export default Vue.extend({
	name: 'EntityList',
	props: {
		rowDetails: {
			type: Array as PropType<string[]>,
			default: () => [],
			required: true
		},
		borrowerIsNotAllowedToAddDeleteEntities: {
			type: Boolean,
			default: false
		}
	}
});
