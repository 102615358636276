
import Vue, { PropType } from 'vue';
import AddNewPrimaryRole from '@/formio-vue/components/primary-role/add-new-primary-role.vue';
import { Customer } from '@sageworks/jpi/';
import { CustomComponentType } from '@sageworks/dynamic-forms';
import EntityList from '../entity-list/entity-list.vue';
import ExistingEntitySelector from '../existing-entity-selector/existing-entity-selector.vue';
import { ConvertCustomComponentTypeToLoanRoleType } from '../../../utils/loan-role-type-helper/LoanRoleTypeHelper';
import { AddLoanRoleParams } from '../../../store/MultiLoanApplicationFormModule/persist-actions/persist-action-params';

export default Vue.extend({
	name: 'EntityRepeater',
	components: { AddNewPrimaryRole, EntityList, ExistingEntitySelector },
	props: {
		entityTypes: {
			type: Array as PropType<Customer.TypeEnum[]>,
			default: () => []
		},
		componentType: {
			type: String as PropType<CustomComponentType>,
			default: () => ''
		},

		rowDetails: {
			type: Array as PropType<string[]>,
			default: () => [],
			required: true
		},
		maxRows: {
			type: Number,
			default: () => 0
		}
	},
	computed: {
		// Get the set of previously entered entities for the current user - only
		// returns data for borrower type users with previous loan applications with entities
		// attached in primary roles that user has access to use.
		existingEntities() {
			return this.directStore.state.MultiLoanApplicationForm.formattedPriorEntities;
		},

		// Read setting to determine if existing entities should be shown on the dynamic loan application
		showExistingEntitiesOnDynamicLoanApplication(): boolean {
			return this.directStore.getters.InstitutionSettings.showExistingEntitiesOnDynamicLoanApplication;
		},
		borrowerIsNotAllowedToAddDeleteEntities(): boolean {
			return !this.directStore.getters.User.isLender && this.directStore.getters.InstitutionSettings.borrowerIsNotAllowedToAddDeleteEntities;
		}
	},
	async mounted() {
		await this.directStore.dispatch.InstitutionSettings.fetchBorrowerIsNotAllowedToAddDeleteEntities();
	},
	methods: {
		// An entity was selected - use the MultiLoanApplicationForm.addLoanRoleForDataObject service to add the entity to the loan
		// and update the UI - NOTE: existing-entity-selector turns off the display of this item after it is selected and emits
		// to here allowing the item to be inserted into the database and UI.
		async handleEntitySelected(borrower: any) {
			if (this.componentType === '') {
				throw new Error('Valid Component type is required');
			}

			// Create the new role object to add to the loan
			const newRole: AddLoanRoleParams = {
				dataObjectType: borrower.borrowerType,
				customerId: borrower.id,
				loanRoleType: ConvertCustomComponentTypeToLoanRoleType(this.componentType as CustomComponentType)
			};

			// Add the new role to the loan in the database and at the top of the screen (entity-list)
			await this.directStore.dispatch.MultiLoanApplicationForm.addLoanRoleForDataObject(newRole);

			// Call the base add code to load the detail data of the existing entity so it can be edited or deleted from the entity list
			this.$emit('add-existing-entity', newRole);
		},

		// User selected to remove an entity - pass on the emit to do the database work but we have to make the call
		// to turn the entity back on in the UI list of existing entities (if it was there when we loaded the form)
		async handleRemoveEntity(index: number) {
			if (this.componentType === '') {
				throw new Error('Valid Component type is required');
			}

			this.$emit('remove-entity', index);

			const entityId = await this.directStore.getters.MultiLoanApplicationForm.getEntityId(
				index,
				ConvertCustomComponentTypeToLoanRoleType(this.componentType as CustomComponentType)
			);

			this.directStore.dispatch.MultiLoanApplicationForm.setEntityHasBeenUsedFlag({ entityId: entityId, hasBeenUsed: false });
		}
	}
});
