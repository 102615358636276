
import Vue, { PropType } from 'vue';
import { OwnerRowData } from '../../../models';
import BeneficialOwnershipTableRowActions from './beneficial-ownership-table-row-actions.vue';
import BeneficialOwnershipTable1071RowActions from './beneficial-ownership-table-1071-row-actions.vue';
import { BeneficialOwnerFormDataModel } from '@sageworks/jpi';
import BeneficialOwnership1071StatusBadge from './beneficial-ownership-1071-status-badge.vue';
import { BeneficialOwnership1071Status } from '../../../utils/beneficial-ownership';

export default Vue.extend({
	name: 'BeneficialOwnershipTableRow',
	components: {
		BeneficialOwnershipTableRowActions,
		BeneficialOwnershipTable1071RowActions,
		BeneficialOwnership1071StatusBadge
	},
	props: {
		row: {
			type: Object as PropType<OwnerRowData>,
			required: true
		},
		is1071: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		borrowerIsNotAllowedToAddDeleteEntities: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			minPercentageFor1071Owners: 0.25
		};
	},
	computed: {
		formattedOwnershipPercentage() {
			return ((this.row.ownershipPercent ?? 0) * 100).toFixed(0);
		},
		isPerson() {
			return this.row.entityType === BeneficialOwnerFormDataModel.OwnerEntityTypeEnum.Person;
		},
		show1071Details() {
			return this.is1071 && this.row.ownershipPercent >= this.minPercentageFor1071Owners && this.isPerson;
		},
		demographic1071Status() {
			if (this.show1071Details) {
				return (this.row.demographic1071InformationStatus as BeneficialOwnership1071Status) ?? BeneficialOwnership1071Status.InProgress;
			}

			return BeneficialOwnership1071Status.NotApplicable;
		}
	}
});
