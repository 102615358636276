
import {
	AutoFactory,
	LoanOfficer,
	OnlinePortalLoanOfficersService
} from '@sageworks/jpi';

import Vue from 'vue';
import moment from 'moment';
import { OnlinePortalApplicationDocumentRequest } from '@/models';

const MARKED_ON_FILE_WAIVED_REASON = 'Document on file';

export default Vue.extend({
	name: 'ApplicationPdfDocumentationSection',
	components: {
	},
	props: {
		loanApplicationId: {
			type: Number,
			required: true
		}
	},

	data() {
		return {
			applicationDocumentRequests: [] as OnlinePortalApplicationDocumentRequest[],
			loanOfficers: [] as LoanOfficer[]
		};
	},

	computed: {
		waivedDocuments(): OnlinePortalApplicationDocumentRequest[] {
			return this.applicationDocumentRequests.filter(x => x.dateWaived && x.waivedReason !== MARKED_ON_FILE_WAIVED_REASON);
		},
		uploadedDocuments(): OnlinePortalApplicationDocumentRequest[] {
			return this.applicationDocumentRequests.filter(x => x.dateUploaded);
		},
		markedOnFileDocuments(): OnlinePortalApplicationDocumentRequest[] {
			return this.applicationDocumentRequests.filter(x => x.dateWaived && x.waivedReason === MARKED_ON_FILE_WAIVED_REASON);
		},
		uploadedDocumentsTableFields() {
			return [
				{ key: 'documentName', label: 'Document Name' },
				{ key: 'dateUploaded', label: 'Upload Date', formatter: this.getFormattedDateString },
			];
		},
		markedOnFileDocumentsTableFields() {
			return [
				{ key: 'documentName', label: 'Document Name' },
				{ key: 'dateWaived', label: 'Marked On', formatter: this.getFormattedDateString },
				{ key: 'waivedByLoanOfficerId', label: 'Marked By', formatter: this.getLoanOfficerName },
				{}
			];
		},
		waivedDocumentsTableFields() {
			return [
				{ key: 'documentName', label: 'Document Name' },
				{ key: 'dateWaived', label: 'Waived On', formatter: this.getFormattedDateString },
				{ key: 'waivedByLoanOfficerId', label: 'Waived By', formatter: this.getLoanOfficerName },
				{ key: 'waivedReason', label: 'Reason' },
			];
		},
	},
	async mounted() {
		await this.initDocumentRequests();
		await this.initLoanOfficers();
	},
	methods: {
		async initDocumentRequests(): Promise<void> {
			await this.directStore.dispatch.ApplicationDocumentRequest.loadPagedDocumentRequests({ loanApplicationId: this.loanApplicationId });
			this.applicationDocumentRequests = Object.values(this.directStore.state.ApplicationDocumentRequest.documentRequests ?? {}).flat();
		},
		async initLoanOfficers(): Promise<void> {
			const loanOfficerIds: Array<number> = [
				...new Set<number>(this.applicationDocumentRequests
					.map((documentRequest: OnlinePortalApplicationDocumentRequest) => documentRequest.waivedByLoanOfficerId)
					.filter((waivedByLoanOfficerId: (number | undefined)) => waivedByLoanOfficerId != null))
			];

			const service = AutoFactory.get(OnlinePortalLoanOfficersService);
			this.loanOfficers = [];
			const getLoanOfficerByIdPromises: Array<Promise<LoanOfficer>> = [];
			for (let i = 0; i < loanOfficerIds.length; ++i) {
				getLoanOfficerByIdPromises.push(service.getLoanOfficerById(loanOfficerIds[i]!));
			}

			this.loanOfficers = await Promise.all(getLoanOfficerByIdPromises);
		},
		getFormattedDateString(fullDateString: string): string {
			return moment(fullDateString).format('MM/DD/YYYY');
		},
		getLoanOfficerName(loanOfficerId: (number | undefined)): string {
			if (loanOfficerId == null) return '';

			const loanOfficer: (LoanOfficer | undefined) = this.loanOfficers.find(x => x.id === loanOfficerId);
			if (loanOfficer == null) return '';

			return `${loanOfficer?.firstName} ${loanOfficer.lastName}`;
		}
	},
});
