
import Vue, { PropType } from 'vue';
import { Customer, Borrower } from '@sageworks/jpi/';
import { CustomComponentLabel, CustomComponentType } from '@sageworks/dynamic-forms';
import IconSelectorButton from '../icon-selector-button/icon-selector-button.vue';
import { PriorEntitiesDisplay } from '@/models/PriorEntitiesDisplay';

export default Vue.extend({
	name: 'ExistingEntitySelector',
	components: {
		IconSelectorButton
	},
	props: {
		entityTypes: {
			type: Array as PropType<Customer.TypeEnum[]>,
			default: () => []
		},
		componentType: {
			type: String as PropType<CustomComponentType>,
			required: true
		},
		borrowers: {
			type: Array as PropType<PriorEntitiesDisplay[]>,
			required: true,
			default: () => []
		}
	},
	computed: {
		roleType(): string {
			return CustomComponentLabel()[this.componentType];
		},
		persons(): Borrower[] {
			if (this.entityTypes.includes(Customer.TypeEnum.Person)) {
				return this.borrowers.filter(
					borrower => borrower.type.toUpperCase() === 'PERSON' && borrower.hasBeenUsed === false && borrower.name.length > 0
				);
			} else {
				return [];
			}
		},
		businesses(): Borrower[] {
			if (this.entityTypes.includes(Customer.TypeEnum.Business)) {
				return this.borrowers.filter(
					borrower => borrower.type.toUpperCase() === 'COMPANY' && borrower.hasBeenUsed === false && borrower.name.length > 0
				);
			} else {
				return [];
			}
		}
	},

	methods: {
		// When a borrower is selected, emit the info so our parent can add the entity to the actual loan, then
		// mark them as used so they can't be used again
		useBorrower(id: number) {
			const foundBorrower = this.borrowers.find((borrower: { id: number }) => borrower.id === id);
			if (foundBorrower) {
				this.$emit('entity-selected', { id: id, borrowerType: foundBorrower.type.toLowerCase() });
				// Setting foundBorrower.hasBeenUsed = true; - works, but not recommended because the data store is the source of truth
				this.directStore.dispatch.MultiLoanApplicationForm.setEntityHasBeenUsedFlag({ entityId: id, hasBeenUsed: true });
			}
		}
	}
});
