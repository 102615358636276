import { ContextDataProperty, CustomComponentType, FetchDataType } from '../../../enums';
import { AddCustomerResult } from '../add-customer/add-customer-result';
import FormComponent from 'formiojs/components/form/Form';
import { ComponentDataHelper, RepeaterWidgetUtils } from '../../../utils';
import { getContextDataValue } from '../../../utils/context-data-helper/context-data-helper';
import { showLoadingPopup } from '../../../utils/modals/loading-modal-helper';
import { convertEntityTypeToCustomerType } from '../../../utils/customer-type-utils/customer-type-utils';
import VueFormioDataListComponent from '../base/vue-formio-data-list-component/vue-formio-data-list-component';
import { getEntityTypes } from '../../../utils/primary-role-utils/entity-type-utils';

export class PrimaryRoleRepeaterWidgetVue extends VueFormioDataListComponent {
	private _isImportingCustomer = false;
	private _currentUserWritableEntities: number[] | null = null;
	private _isLoadingUserWritableEntities = true;
	private _skipUserWriteAccessCheck = false;
	protected showEditGridNav = false;

	public get isLoadingUserWritableEntities() {
		return this._isLoadingUserWritableEntities && !this._skipUserWriteAccessCheck;
	}

	public get isImportingCustomer() {
		return this._isImportingCustomer;
	}

	private get loanApplicationId() {
		return getContextDataValue(this, ContextDataProperty.LoanApplicationId);
	}

	private get shouldAutoOpen() {
		return !this.dataValue?.length && this.autoOpenEnabled && !this.editRows.length && !this.builderMode;
	}

	private userHasWriteAccess(row: any) {
		if (this._skipUserWriteAccessCheck) {
			return true;
		}
		if (!this._currentUserWritableEntities) {
			return false;
		}

		const entityId = this.rowGetEntityId(row);
		return this._currentUserWritableEntities.indexOf(+entityId) > -1;
	}

	init() {
		this._isImportingCustomer = false;
		super.init();
		this.autoOpen();
	}

	public async attach(element: any) {
		await super.attach(element);

		await this.loadInitData();
		if (this.hasOpenRows()) {
			this.emitEditEvent();
		}
		if (this.autoOpenEnabled) {
			await this.saveAutoOpenedRows();
		}
	}

	async saveAutoOpenedRows() {
		const savePromises: Promise<any>[] = [];
		if (!this.editRows?.length) {
			return;
		}
		this.editRows.forEach((row: any, index: number) => {
			const rowData = row?.data as any;
			const autoOpenFormType = this.component.autoOpenFormType.value;
			if (rowData[autoOpenFormType] == null) {
				return;
			}

			if (rowData[this.component.autoOpenFormType.value]?.data?.id < 1) {
				savePromises.push(this.partialSave(index));
			}
		});

		await Promise.all(savePromises);
	}

	async saveNewRow(rowIndex: number, addingExistingEntity: boolean = false) {
		this.saveRow(rowIndex);

		// If this is not an existing entity, must pop up the edit screen
		if (!addingExistingEntity) {
			const savePromise = this.partialSave(rowIndex).finally(() => {
				return this.editRow(rowIndex);
			});
			showLoadingPopup(savePromise);

			await savePromise;
		}
	}

	protected shouldPartialSaveOnSaveRow(_rowIndex: number, isNew: boolean) {
		return this.autoOpenEnabled || !isNew;
	}

	// addingExistingEntity is used to determine if we should pop up the edit screen after saving the row - if it's a new entity, we should
	protected async saveEntity(rowIndex: number, data: AddCustomerResult, addingExistingEntity: boolean = false) {
		// Timeout is required, if we call saveOrEditRow immediately, the editGrid/primary role component will be replaced by the single editRow
		// This only seems to affect borrower view, and only if we skip the primary-role-add-row-popup, so we don't want a delay if we're a lender
		if (this.isLender) {
			await this.saveOrEditRow(rowIndex, data, addingExistingEntity);
		} else {
			setTimeout(async () => {
				await this.saveOrEditRow(rowIndex, data, addingExistingEntity);
			}, 1000);
		}
	}

	// addingExistingEntity is used to determine if we should pop up the edit screen after saving the row - if it's a new entity, we should
	protected async saveOrEditRow(rowIndex: number, addCustomerResult: AddCustomerResult, addingExistingEntity: boolean = false) {
		const formComponent: FormComponent = this.editRows[rowIndex].components[0];

		await formComponent.subFormReady;
		formComponent.setValue(
			{
				...formComponent.subForm.getValue(),
				data: this.buildSaveData(addCustomerResult)
			},
			{ fromSubmission: true }
		);

		this.editRows[rowIndex].data = formComponent.data;
		await this.saveNewRow(rowIndex, addingExistingEntity);
		await this.getWritableEntities(true);
		this._currentUserWritableEntities = null;
	}

	protected buildSaveData(addCustomerResult: AddCustomerResult) {
		return RepeaterWidgetUtils.createRowData(addCustomerResult, this.parentLoanRoleId);
	}

	async isUseBorrowerAccessRestrictionsSettingEnabled(): Promise<boolean> {
		return await ComponentDataHelper.fetchData<boolean>(this, {
			fetchType: FetchDataType.UseBorrowerAccessRestrictions,
			fetchContext: {}
		});
	}

	async getWritableEntities(reloadFromServer: boolean): Promise<number[]> {
		return await ComponentDataHelper.fetchData<number[]>(this, {
			fetchType: FetchDataType.CurrentUserWritableEntities,
			fetchContext: { reloadFromServer: reloadFromServer }
		});
	}

	async loadInitData() {
		if (this._currentUserWritableEntities !== null) {
			return;
		}
		let useBorrowerAccessRestrictionsSettingEnabled = await this.isUseBorrowerAccessRestrictionsSettingEnabled();
		this._skipUserWriteAccessCheck = this.isLender || !useBorrowerAccessRestrictionsSettingEnabled;
		if (this._skipUserWriteAccessCheck) {
			this._currentUserWritableEntities = [];
			this.redraw();
			return;
		}
		const writableEntities = await this.getWritableEntities(false);
		this._currentUserWritableEntities = writableEntities;
		this._isLoadingUserWritableEntities = false;
		this.redraw();
	}

	protected async preAttachVueComponentSetup() {
		const entityTypes = await getEntityTypes(this.component.form);
		this.vueComponent.$props.entityTypes = entityTypes.map(e => convertEntityTypeToCustomerType(e));
	}

	public async autoOpen() {
		const componentTypeToOpen = this.component.autoOpenFormType?.value as CustomComponentType;

		if (this.shouldAutoOpen) {
			const addCustomerResult = {
				customerType: convertEntityTypeToCustomerType(componentTypeToOpen),
				[componentTypeToOpen]: {
					data: { id: -1 }
				}
			} as AddCustomerResult;

			this.createRow(this.buildSaveData(addCustomerResult), 0);
			const formComponent: FormComponent = this.editRows[0].components[0];

			await formComponent.subFormReady;
			formComponent.setValue(
				{
					...formComponent.subForm.getValue(),
					data: this.buildSaveData(addCustomerResult)
				},
				{ fromSubmission: true }
			);

			this.setPristine(false);
		}
	}
}
