import {
	AutoFactory,
	OnlinePortalAdobeEsignService,
	DynamicApplicationAdobePhoneAuthenticationSettingsService,
	DynamicApplicationSettingValue,
	OnlinePortalLoanApplicationsService,
	AgreementStatus,
	RecipientSignatureStatus,
	LoanApplication,
	LoanApplicationApplicant
} from '@sageworks/jpi';

import Vue from 'vue';
import { FileHelper } from '@sageworks/core-logic';
import { defineModule, localActionContext } from 'direct-vuex';
import { LoanRoleType } from '@sageworks/dynamic-forms';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, AdobeESignModule);

export interface AdobeESignModuleState {
	customerCanUseEsign: boolean;
	customerHasPhoneAuthEnabled: false;
	adobeAgreementStatus: AgreementStatus;
	applicationHasAdobeAgreement: boolean;
	applicationRecipientInformation: ApplicationRecipientInformation[];
	adobeTemplateStatuses: Map<number, string>;
}

export interface ApplicationRecipientInformation {
	role: string | '';
	name: string | '';
	email: string | '';
}

const AdobeESignModule = defineModule({
	namespaced: true,

	state: () => {
		return {
			customerCanUseEsign: false,
			customerHasPhoneAuthEnabled: false,
			applicationHasAdobeAgreement: false,
			adobeAgreementStatus: {},
			adobeTemplateStatuses: new Map<number, string>()
		} as AdobeESignModuleState;
	},
	mutations: {
		SET_USER_CAN_USE_ESIGN(state, customerCanUseEsign: boolean) {
			Vue.set(state, 'customerCanUseEsign', customerCanUseEsign);
		},
		SET_ADOBE_AGREEMENT_STATUS(state, adobeAgreementStatus: AgreementStatus) {
			Vue.set(state, 'adobeAgreementStatus', adobeAgreementStatus);
		},
		SET_APPLICATION_HAS_ADOBE_AGREEMENT(state, applicationHasAdobeAgreement: boolean) {
			state.applicationHasAdobeAgreement = applicationHasAdobeAgreement;
		},
		SET_USER_PHONE_AUTH_ENABLED(state, customerHasPhoneAuthEnabled: boolean) {
			Vue.set(state, 'customerHasPhoneAuthEnabled', customerHasPhoneAuthEnabled);
		},
		SET_APPLICATION_RECIPIENT_INFORMATION(state, loanApplicationApplicants: LoanApplicationApplicant[]) {
			var applicationRecipientInformation: ApplicationRecipientInformation[] = [];

			loanApplicationApplicants.forEach(loanApplicationApplicant => {
				var role = '';
				if (loanApplicationApplicant.roles) {
					// We should never have a borrower with multiple roles... Grabbing the first one of the list will suffice
					var borrowerType = loanApplicationApplicant.roles.length > 0 ? loanApplicationApplicant.roles[0] : '';

					switch (borrowerType) {
						case LoanApplicationApplicant.RolesEnum.PrimaryBorrower:
							role = LoanRoleType.PrimaryBorrower;
							break;
						case LoanApplicationApplicant.RolesEnum.CoBorrower:
							role = LoanRoleType.CoBorrower;
							break;
						case LoanApplicationApplicant.RolesEnum.Guarantor:
							role = LoanRoleType.Guarantor;
							break;
						case LoanApplicationApplicant.RolesEnum.CoSigner:
							role = LoanRoleType.CoSigner;
							break;
					}
				}

				applicationRecipientInformation.push({
					name: loanApplicationApplicant.name ?? '',
					email: loanApplicationApplicant.email ?? '',
					role: role
				});
			});

			state.applicationRecipientInformation = applicationRecipientInformation;
		},
		SET_ADOBE_TEMPLATE_STATUSES(state, { documentRequestId, status }: { documentRequestId: number; status: string }) {
			state.adobeTemplateStatuses.set(documentRequestId, status);
			state.adobeTemplateStatuses = new Map(state.adobeTemplateStatuses.entries());
		}
	},
	actions: {
		async getUserCanUseEsign(context, applicationType: LoanApplication.TypeEnum): Promise<void> {
			const { commit } = actionContext(context);

			const esignService = AutoFactory.get(OnlinePortalAdobeEsignService);
			const customerCanUseEsign = await esignService.getCustomerCanUseEsign(applicationType);

			commit.SET_USER_CAN_USE_ESIGN(customerCanUseEsign);
		},
		async getUserPhoneAuthEnabled(context, applicationType: LoanApplication.TypeEnum): Promise<void> {
			const { commit } = actionContext(context);

			const adobeAuthService = AutoFactory.get(DynamicApplicationAdobePhoneAuthenticationSettingsService);
			const phoneAuthSettings = await adobeAuthService.getAll();
			var phoneAuthEnabled = false;
			const dynAppSetting = DynamicApplicationSettingValue.DynamicApplicationSettingEnum;
			switch (applicationType) {
				case LoanApplication.TypeEnum.Personal:
					phoneAuthEnabled =
						phoneAuthSettings.items?.find(s => s.dynamicApplicationSetting === dynAppSetting.PersonAppsAdobeAuthenticationMethod)?.settingValue ??
						false;
					break;
				case LoanApplication.TypeEnum.Business:
					phoneAuthEnabled =
						phoneAuthSettings.items?.find(s => s.dynamicApplicationSetting === dynAppSetting.BusinessAppsAdobeAuthenticationMethod)?.settingValue ??
						false;
					break;
				case LoanApplication.TypeEnum.Nonprofit:
					phoneAuthEnabled =
						phoneAuthSettings.items?.find(s => s.dynamicApplicationSetting === dynAppSetting.NonprofitAppsAdobeAuthenticationMethod)
							?.settingValue ?? false;
					break;
				case LoanApplication.TypeEnum.Sba:
					phoneAuthEnabled =
						phoneAuthSettings.items?.find(s => s.dynamicApplicationSetting === dynAppSetting.SBaAppsAdobeAuthenticationMethod)?.settingValue ??
						false;
					break;
			}

			commit.SET_USER_PHONE_AUTH_ENABLED(phoneAuthEnabled);
		},
		async exportApplicationToPdf(context, { loanApplicationNumber, html }): Promise<void> {
			const loanApplicationService = AutoFactory.get(OnlinePortalLoanApplicationsService);
			const exportReturn = await loanApplicationService.convertApplicationHtmlToPdf(html);

			if (exportReturn.content) {
				const base64Content = FileHelper.getBase64FromDataUri(exportReturn.content);
				const mimeType = FileHelper.getMimeTypeFromDataUri(exportReturn.content);
				FileHelper.downloadFile(base64Content, `Loan_Application_${loanApplicationNumber}.pdf`, mimeType);
			}
		},
		async sendApplicationEmailsForSignatures(context, { applicationId, html }): Promise<void> {
			const esignService = AutoFactory.get(OnlinePortalAdobeEsignService);
			return esignService.sendApplicationEmailSignatureRequests(applicationId, html);
		},
		async resendApplicationEmailsForSignatures(context, { applicationId, html }): Promise<void> {
			const esignService = AutoFactory.get(OnlinePortalAdobeEsignService);
			return esignService.resendApplicationEmailSignatureRequests(applicationId, html);
		},
		async fetchLoanApplicationRecipientStatuses(context, { applicationId }): Promise<void> {
			const { commit } = actionContext(context);

			const esignService = AutoFactory.get(OnlinePortalAdobeEsignService);
			var agreementStatus: AgreementStatus = await esignService.getApplicationRecipientStatuses(applicationId);
			commit.SET_ADOBE_AGREEMENT_STATUS(agreementStatus);
		},
		async checkIfApplicationHasAdobeAgreement(context, { applicationId }): Promise<void> {
			const { commit } = actionContext(context);

			const esignService = AutoFactory.get(OnlinePortalAdobeEsignService);
			var applicationHasAdobeAgreement: boolean = await esignService.applicationHasAgreement(applicationId);

			commit.SET_APPLICATION_HAS_ADOBE_AGREEMENT(applicationHasAdobeAgreement);
		},
		async fetchLoanApplicationRecipientInformation(context, { applicationId }): Promise<void> {
			const { commit } = actionContext(context);

			const esignService = AutoFactory.get(OnlinePortalAdobeEsignService);
			var loanApplicationApplicants: LoanApplicationApplicant[] = await esignService.getApplicationRecipientInformation(applicationId);

			commit.SET_APPLICATION_RECIPIENT_INFORMATION(loanApplicationApplicants);
		},
		async sendAdobeTemplatesForSignature(context, { adobeTemplateDocumentNameMappingId, customerId, documentRequestId }): Promise<any> {
			const esignService = AutoFactory.get(OnlinePortalAdobeEsignService);
			const response = await esignService.sendAdobeTemplatesForSignature({ adobeTemplateDocumentNameMappingId, customerId, documentRequestId });
			return response;
		},
		async fetchTemplateStatus(context, { documentRequestId, documentNamesId }): Promise<string> {
			const { commit } = actionContext(context);
			const esignService = AutoFactory.get(OnlinePortalAdobeEsignService);
			const response = await esignService.fetchTemplateStatus({ documentRequestId, documentNamesId });
			commit.SET_ADOBE_TEMPLATE_STATUSES({ documentRequestId, status: response });

			return response;
		},
		async resetTemplateEsignature(context, { customerId, documentRequestId }): Promise<any> {
			const esignService = AutoFactory.get(OnlinePortalAdobeEsignService);
			const response = await esignService.resetTemplateEsignature({ customerId, documentRequestId });
			return response;
		},
		async getTemplateUrl(context, { documentRequestId }): Promise<string> {
			const esignService = AutoFactory.get(OnlinePortalAdobeEsignService);
			const response = await esignService.getTemplateSigningUrl(documentRequestId);
			return response;
		}
	},
	getters: {
		adobeAgreementCreated: state => {
			return Boolean(state.adobeAgreementStatus?.requestedDate);
		},
		adobeRecipientStatuses: state => {
			return state.adobeAgreementStatus?.recipientStatuses ?? null;
		},
		allRecipientsHaveSignedAgreement: state => {
			if (state.adobeAgreementStatus && state.adobeAgreementStatus.recipientStatuses) {
				let recipientsMissingSignature: RecipientSignatureStatus[] = state.adobeAgreementStatus.recipientStatuses.filter(rec => {
					return rec.dateSigned === null || rec.dateSigned === undefined || rec.dateSigned === '';
				});

				return recipientsMissingSignature.length === 0;
			}

			return false;
		}
	}
});

export default AdobeESignModule;
