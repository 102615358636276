
import Vue, { PropType } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
	AnonymousBorrowerMinimumAccessService,
	AutoFactory,
	Permission,
	EmailLog,
	Demographic1071Summary
} from '@sageworks/jpi';
import SidebarDrawer from '../../../components/sidebar-drawer/sidebar-drawer.vue';
import { mapGetters } from 'vuex';
import { formatUrl } from '../../../utils/DemographicInformationUtils';
import DemographicStatusBadge from './demographic-forms-status-badge.vue';
import SendDemographicRequestPopup from './send-demographic-request-popup.vue';

export default Vue.extend({
	name: 'DemographicForm1071',
	components: {
		SendDemographicRequestPopup,
		DemographicStatusBadge,
		SidebarDrawer,
		FontAwesomeIcon
	},
	props: {
		data: {
			type: Object as PropType<Demographic1071Summary>,
			required: true
		},
		customerName: { 
			type: String,
			required: true
		},
		customerEmail: {
			type: String,
			default: ''
		},
		applicationId: {
			type: Number,
			required: true
		},
	},
	data() {
		return {
			isDrawerOpen: false,
			loadingDemographicFormUrl: false,
			origin: window.location.origin,
			demographicFormUrl: undefined as string | undefined,
			isRequestPopupOpen: false
		};
	},
	computed: {
		...mapGetters('InstitutionSettings', ['allowBorrowerFillOut1071DemographicFormForAllOwners', 'allowBorrowerSendOut1071DemographicForm']),
		...mapGetters('User', ['isLender']),
		emailLogs(): EmailLog[] {
			return this.data.emailRequestLog ?? [];
		},
		allowTheUserToEnterDemographicInformation(): boolean {
			return this.directStore.getters.LenderAuthorizationModule.canAccessFeature(Permission.ProductFeatureEnum.AllowTheUserToEnterDemographicInformation);
		},
		allowTheUserToSendDemographicRequest(): boolean {
			return this.directStore.getters.LenderAuthorizationModule.canAccessFeature(Permission.ProductFeatureEnum.AllowTheUserToSendDemographicRequest);
		},
		showSendEmailRequest(): boolean {
			if (this.isLender) {
				return this.allowTheUserToSendDemographicRequest;
			}
			return this.allowBorrowerSendOut1071DemographicForm;
		},
		isCompleted() {
			return this.data.status === Demographic1071Summary.StatusEnum.Completed;
		},
		showEnterDemographicInfoButton(): boolean {
			const allowTheUserToEnterDemographicInformation = this.allowTheUserToEnterDemographicInformation;
			const allowBorrowerFillOut1071DemographicFormForAllOwners  = this.allowBorrowerFillOut1071DemographicFormForAllOwners;
			const isCompleted = this.isCompleted;

			if (this.isLender) {
				return allowTheUserToEnterDemographicInformation;
			}
			return allowBorrowerFillOut1071DemographicFormForAllOwners && !isCompleted;
		},
	},
	async mounted() {
		window.addEventListener('message', this.handleFormSubmitted);
	},
	destroyed() {
		window.removeEventListener('message', this.handleFormSubmitted);
	},
	methods: {
		openModal() {
			this.isRequestPopupOpen = true;
		},
		closeModal() {
			this.isRequestPopupOpen = false;
		},
		hideDrawer() {
			this.isDrawerOpen = false;
		},
		openDrawer() {
			this.isDrawerOpen = true;
		},
		handleFormSubmitted({ origin, data }: MessageEvent<{ canceled: boolean; success: boolean; eventType: string }>) {
			if (!this.demographicFormUrl) {
				return;
			}

			// Ensure the event contains the correct origin so we know it came from the nested iframe
			const expectedOrigin = new URL(this.demographicFormUrl).origin;
			if (origin !== expectedOrigin || data.eventType !== 'SUBMIT_1071_DEMOGRAPHIC_FORM') {
				return;
			}

			this.hideDrawer();
			const { customerId, proposedLoanId } = this.data;

			if (data.success && customerId && proposedLoanId) {
				this.directStore.dispatch.DemographicForm.reload1071FormStatus({ proposedLoanId, customerId });
			}
		},
		async openDemographicForm(customerId?: number, proposedLoanId?: number) {
			if (!customerId || !proposedLoanId) {
				return;
			}
			// if the url is already fetched then simply open the drawer
			if (this.demographicFormUrl) {
				this.openDrawer();
				return;
			}

			// If we are already loading the url or don't have the details to fetch the url, then ignore this action
			if (this.loadingDemographicFormUrl) {
				return;
			}

			try {
				this.loadingDemographicFormUrl = true;

				// fetch the demographic form url
				const service = AutoFactory.get(AnonymousBorrowerMinimumAccessService);
				const fetchedUrl = await service.create1071DemographicForm1071Url(customerId, proposedLoanId);

				// Format it since we need to inform it is in a nested window
				this.demographicFormUrl = formatUrl(fetchedUrl, this.origin);

				this.openDrawer();
			} finally {
				this.loadingDemographicFormUrl = false;
			}
		},
		async emailSent() {
			await this.directStore.dispatch.DemographicForm.load1071FormLogs({ loanApplicationId: this.applicationId });
		}
	}
});
