import { defineModule, localActionContext } from 'direct-vuex';
import * as SbaFormService from '@/logic/SbaForms.service';
import { SbaFormUtils } from '@/utils';
import { SbaForm } from '@sageworks/jpi';
import { ImmutableArrayHelper } from '@sageworks/core-logic';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, SbaFormModule);

const upsertFormToState = (context: any, sbaForm: SbaForm) => {
	const { commit, state } = actionContext(context);
	const { sbaForms = [] } = state;

	const index = sbaForms.findIndex(x => x.id === sbaForm.id);

	if (index >= 0) {
		const newSbaFormList = ImmutableArrayHelper.setElementAt(sbaForms, sbaForm, index);
		commit.SET_SBA_FORMS(newSbaFormList);
	} else {
		commit.SET_SBA_FORMS([...sbaForms, sbaForm]);
	}
};

export interface SbaFormModuleState {
	sbaForms?: SbaForm[];
}
const SbaFormModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			sbaForms: undefined
		} as SbaFormModuleState;
	},
	mutations: {
		SET_SBA_FORMS(state, sbaForms: any[]) {
			state.sbaForms = sbaForms;
		}
	},
	actions: {
		async loadSbaForms(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { commit } = actionContext(context);

			try {
				const sbaForms = await SbaFormService.getSbaForms(loanApplicationId);
				commit.SET_SBA_FORMS(sbaForms);
				return sbaForms;
			} catch (err) {
				// This catch is needed since the endpoint returns 404 if user does not have write access to the loan application
				// eslint-disable-next-line no-console
				console.error(err);
			}
		},
		async approveSbaForm(context, { sbaFormId, reason, userId }: { sbaFormId: number; reason: string; userId: number }) {
			const { state } = actionContext(context);
			const sbaForm = state.sbaForms?.find(x => x.id === sbaFormId);

			if (sbaForm) {
				const updatedSbaForm = await SbaFormService.approveSbaForm(sbaForm, userId, reason);
				upsertFormToState(context, updatedSbaForm);
			}
		},
		async declineSbaForm(context, { sbaFormId, reason, userId }: { sbaFormId: number; reason: string; userId: number }) {
			const { state } = actionContext(context);
			const sbaForm = state.sbaForms?.find(x => x.id === sbaFormId);

			if (sbaForm) {
				const updatedSbaForm = await SbaFormService.declineSbaForm(sbaForm, userId, reason);
				upsertFormToState(context, updatedSbaForm);
			}
		},
		async waiveSbaForm(context, { sbaFormId, reason, userId }: { sbaFormId: number; reason: string; userId: number }) {
			const { state } = actionContext(context);
			const sbaForm = state.sbaForms?.find(x => x.id === sbaFormId);

			if (sbaForm) {
				const updatedSbaForm = await SbaFormService.waiveSbaForm(sbaForm, userId, reason);
				upsertFormToState(context, updatedSbaForm);
			}
		},
		async resetSbaFormStatus(context, { sbaFormId }: { sbaFormId: number }) {
			const { state } = actionContext(context);
			const sbaForm = state.sbaForms?.find(x => x.id === sbaFormId);

			if (sbaForm) {
				const updatedSbaForm = await SbaFormService.resetSbaFormStatus(sbaForm);
				upsertFormToState(context, updatedSbaForm);
			}
		}
	},
	getters: {
		sbaFormsByCustomers({ sbaForms }) {
			return SbaFormUtils.listToMapByCustomerId(sbaForms ?? []);
		}
	}
});

export default SbaFormModule;
