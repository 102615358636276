import { AutoFactory, OnlinePortalDocumentsService } from '@sageworks/jpi';
import { FileHelper } from '@sageworks/core-logic';

export class DocLibraryHelper {
	public static async downloadDocFromDocLibrary(documentId: number) {
		const service = AutoFactory.get(OnlinePortalDocumentsService);
		const [docInfo, docContent] = await Promise.all([service.getById(documentId), service.getContentById(documentId)]);

		if (docContent.content && docInfo.fileName) {
			const base64Content = FileHelper.getBase64FromDataUri(docContent.content);
			const mimeType = FileHelper.getMimeTypeFromDataUri(docContent.content);
			FileHelper.downloadFile(base64Content, docInfo.fileName, mimeType);
		}
	}
}
