import { locateRoles, setRoles, locateRelatedRoles, setRelatedRoles } from '../../utils/form-data-utils';
import {
	DataObject as DataObjectTypings,
	FormDataSubmissionDataMapper,
	LoanRoleType,
	PresetDataFieldsByDataObject,
	TemplateDataField
} from '@sageworks/dynamic-forms';
import {
	AggregatedFormDataModel,
	AutoFactory,
	BeneficialOwnerFormDataModel,
	BusinessInvestment,
	DataObject,
	FormioRenderDataModel,
	LoanApplicationProposedLien,
	LoanRoleDataModel,
	OnlinePortalRealEstatePropertiesService,
	PersonalFinancialData,
	RealEstateFinancialData,
	RealEstateProperty,
	RelatedLoanRoleData,
	OnlinePortalLoanApplicationsService
} from '@sageworks/jpi';
import { defineModule } from 'direct-vuex';
import Vue from 'vue';
import { LoanApplicationDataModuleState } from '../LoanApplicationDataModule';
import { LoanApplicationMetadataModuleState } from '../LoanApplicationMetadataModule';
import PersistActions from './persist-actions';
import { MultiLoanApplicationFormModuleState } from './state';
import { actionContext } from './store-helper';
import { ImmutableArrayHelper } from '@sageworks/core-logic';
import { getEntitiesOnLoan } from '@sageworks/dynamic-forms/src/utils/form-data-helpers/form-data-loan-role-helpers';
import { formatDataFieldIdKey } from '@sageworks/dynamic-forms/src/components/form-components/simple-inputs/extended-field-helper';
import { BusinessObjectPropertiesModuleState } from '../CustomComponentPropertiesModule';

const MultiLoanApplicationFormModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			loanIndex: 0,
			renderData: undefined,
			submissionDataLookup: {},
			entityIdsOnLoan: [],
			is1071Loan: false,
			formattedPriorEntities: []
		} as MultiLoanApplicationFormModuleState;
	},
	mutations: {
		SET_RENDER_DATA: (state, { renderData }: { renderData?: FormioRenderDataModel }) => {
			state.renderData = renderData;
		},
		SET_CURRENT_LOAN_INDEX: (state, { loanIndex }: { loanIndex: number }) => {
			state.loanIndex = loanIndex;
		},
		SET_SUBMISSION_DATA: (state, { loanIndex, submissionData }: { loanIndex: number; submissionData: any }) => {
			Vue.set(state.submissionDataLookup, loanIndex, submissionData);
		},
		CLEAR_SUBMISSION_DATA: state => {
			state.submissionDataLookup = {};
		},
		UPSERT_DATA_OBJECT: (state, { type, dataObject }: { type: DataObjectTypings.TypeEnum; dataObject: DataObject }) => {
			const { renderData } = state;
			const formData = { ...renderData?.formData, dataObjects: renderData?.formData?.dataObjects ?? {} };
			if (!formData.dataObjects) {
				return;
			}

			const dataObjects = (formData.dataObjects ?? {})[type] ?? [];
			formData.dataObjects[type] = ImmutableArrayHelper.upsert(dataObjects, dataObject, x => x.rowId === dataObject.rowId);

			if (!renderData) {
				return;
			}

			Vue.set(renderData, 'formData', formData);
		},
		REMOVE_DATA_OBJECT: (state, { type, id }: { type: DataObjectTypings.TypeEnum; id: number }) => {
			const { renderData } = state;
			const formData = { ...renderData?.formData };
			if (!formData.dataObjects) {
				return;
			}

			formData.dataObjects[type] = (formData.dataObjects[type] ?? []).filter(x => x.rowId !== id);

			if (!renderData) {
				return;
			}

			Vue.set(renderData, 'formData', formData);
		},
		UPSERT_PROPOSED_LIEN: (state, { proposedLien }: { proposedLien: LoanApplicationProposedLien }) => {
			const { renderData } = state;
			const formData = { ...renderData?.formData, proposedLiens: renderData?.formData?.proposedLiens ?? [] };
			if (!formData.proposedLiens) {
				return;
			}

			formData.proposedLiens = ImmutableArrayHelper.upsert(
				formData.proposedLiens,
				{ id: proposedLien.id, collateralID: proposedLien.collateralId },
				x => x.id === proposedLien.id
			);

			if (!renderData) {
				return;
			}

			Vue.set(renderData, 'formData', formData);
		},
		REMOVE_PROPOSED_LIEN: (state, { id }: { id: number }) => {
			const { renderData } = state;
			const formData = { ...renderData?.formData };
			if (!formData.proposedLiens) {
				return;
			}

			formData.proposedLiens = (formData.proposedLiens ?? []).filter(x => x.id !== id);

			state.renderData = { ...renderData, formData };
		},
		UPSERT_LOAN_ROLE: (state, { loanRole, loanRoleType }: { loanRole: LoanRoleDataModel; loanRoleType: LoanRoleType }) => {
			const { renderData } = state;
			const formData = { ...renderData?.formData };
			const roles = locateRoles(formData, loanRoleType);

			const updatedRolesList = ImmutableArrayHelper.upsert(roles, loanRole, x => x.dataObjectID === loanRole.dataObjectID);
			setRoles(formData, loanRoleType, updatedRolesList);

			if (!renderData) {
				return;
			}

			Vue.set(renderData, 'formData', formData);
		},
		REMOVE_LOAN_ROLES: (state, { ids, loanRoleType }: { ids: number[]; loanRoleType: LoanRoleType }) => {
			const { renderData } = state;
			const formData = { ...renderData?.formData };
			const roles = locateRoles(formData, loanRoleType).filter(x => !ids.includes(x.dataObjectID as number));

			setRoles(formData, loanRoleType, roles);

			if (!renderData) {
				return;
			}

			Vue.set(renderData, 'formData', formData);
		},
		SET_ENTITY_IDS_ON_LOAN: (state, { entityIdsOnLoan }: { entityIdsOnLoan: number[] }) => {
			state.entityIdsOnLoan = entityIdsOnLoan;
		},
		UPSERT_BENEFICIAL_OWNERS: (state, { beneficialOwners }: { beneficialOwners: BeneficialOwnerFormDataModel[] }) => {
			const { renderData } = state;
			const formData = { ...renderData?.formData };
			const filteredExistingBeneficialOwners = (formData.beneficialOwners ?? []).filter(x => !beneficialOwners.some(y => y.id === x.id));

			const newFormData = {
				...formData,
				beneficialOwners: [...filteredExistingBeneficialOwners, ...beneficialOwners]
					// Ensure we don't update the existing ordering of the list
					.sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
			};

			if (!renderData) {
				return;
			}

			Vue.set(renderData, 'formData', newFormData);
		},
		DELETE_BENEFICIAL_OWNER: (state, { id }: { id: number }) => {
			const { renderData } = state;
			const formData = { ...renderData?.formData };

			const newFormData = {
				...formData,
				beneficialOwners: (formData.beneficialOwners ?? []).filter(x => x.id !== id)
			};

			if (!renderData) {
				return;
			}

			Vue.set(renderData, 'formData', newFormData);
		},
		UPSERT_PFS: (state, { pfs }: { pfs: PersonalFinancialData }) => {
			const { renderData } = state;
			const formData = { ...renderData?.formData } as AggregatedFormDataModel;

			const updatedPfsList = ImmutableArrayHelper.upsert(formData.personalFinancialMappings ?? [], pfs, x => x.id === pfs.id);

			const newFormData: AggregatedFormDataModel = {
				...formData,
				personalFinancialMappings: updatedPfsList
			};

			if (!renderData) {
				return;
			}

			Vue.set(renderData, 'formData', newFormData);
		},
		REMOVE_PFS: (state, { id }: { id: number }) => {
			const { renderData } = state;
			const formData = { ...renderData?.formData } as AggregatedFormDataModel;

			const newFormData: AggregatedFormDataModel = {
				...formData,
				personalFinancialMappings: (formData.personalFinancialMappings ?? []).filter(x => x.id === id)
			};

			if (!renderData) {
				return;
			}

			Vue.set(renderData, 'formData', newFormData);
		},
		UPSERT_RELATED_ROLE: (state, { relatedRole, roleType }: { relatedRole: RelatedLoanRoleData; roleType: LoanRoleType }) => {
			const { renderData } = state;
			const formData: AggregatedFormDataModel = { ...renderData?.formData };
			const relatedRoles = locateRelatedRoles(formData, roleType);

			const updatedRolesList = ImmutableArrayHelper.upsert(relatedRoles, relatedRole, x => x.id === relatedRole.id);
			setRelatedRoles(formData, roleType, updatedRolesList);

			if (!renderData) {
				return;
			}

			Vue.set(renderData, 'formData', formData);
		},
		REMOVE_RELATED_ROLES: (state, { ids, roleType }: { ids: number[]; roleType: LoanRoleType }) => {
			const { renderData } = state;
			const formData: AggregatedFormDataModel = { ...renderData?.formData };
			const roles = locateRelatedRoles(formData, roleType).filter(x => !ids.includes(x.id!));

			setRelatedRoles(formData, roleType, roles);

			if (!renderData) {
				return;
			}

			Vue.set(renderData, 'formData', formData);
		},
		SET_REAL_ESTATE_FINANCIALS: (state, { realEstateFinancials }: { realEstateFinancials: Array<RealEstateFinancialData> }) => {
			state.renderData = {
				...state.renderData,
				formData: {
					...state.renderData?.formData,
					realEstateFinancials
				}
			};
		},
		SET_BUSINESS_INVESTMENTS: (state, { businessInvestments }: { businessInvestments: Array<BusinessInvestment> }) => {
			state.renderData = {
				...state.renderData,
				formData: {
					...state.renderData?.formData,
					businessInvestments
				}
			};
		},
		SET_IS_1071_FLAG: (state, { value }: { value: boolean }) => {
			state.is1071Loan = value;
		},

		SET_PRIOR_ENTITIES: (state, { formattedPriorEntities }: { formattedPriorEntities: any[] }) => {
			state.formattedPriorEntities = formattedPriorEntities;
		}
	},
	actions: {
		...PersistActions,
		async loanChange(context, { loanIndex }: { loanIndex: number }) {
			const { commit, rootState, dispatch } = actionContext(context);
			const { metadata } = rootState.LoanApplicationMetadata as LoanApplicationMetadataModuleState;

			if (loanIndex >= metadata.length) {
				loanIndex = loanIndex > 0 ? loanIndex - 1 : 0;
			}

			commit.SET_CURRENT_LOAN_INDEX({ loanIndex });
			await dispatch.loadLoanData({ loanIndex });
		},
		async loadLoanData(context, { loanIndex }: { loanIndex: number }) {
			const { commit, rootState, rootDispatch, dispatch } = actionContext(context);

			const { metadata } = rootState.LoanApplicationMetadata as LoanApplicationMetadataModuleState;
			const { presetDataFieldsByDataObject: presetDataFields } = rootState.LoanApplicationMetadata as LoanApplicationMetadataModuleState;
			const { renderDataLookup } = rootState.LoanApplicationData as LoanApplicationDataModuleState;

			const loanMappingId = metadata[loanIndex]?.loanMappingId;

			if (loanMappingId == null) {
				throw new Error('Cannot locate loan mapping Id');
			}

			// Load form data
			await rootDispatch.LoanApplicationData.loadRenderData({ loanMappingId });
			commit.SET_RENDER_DATA({ renderData: renderDataLookup[loanMappingId] });
			const formData = renderDataLookup[loanMappingId].formData;

			if (!formData) {
				throw new Error(`Could not properly load form data [loanMappingId: ${loanMappingId}]`);
			}

			await dispatch.setFormData({ loanIndex, formData, presetDataFields });
			await dispatch.loadEntitiesFromPreviousLoans();
		},

		// Called without an ID (which is from a borrower), load all entities from previous loans - the endpoint
		// will get the BankCustomerUsersID from the context so we don't have to pass it here.
		async loadEntitiesFromPreviousLoans(context) {
			const { commit } = actionContext(context);
			// Get the controller API
			const service = AutoFactory.get(OnlinePortalLoanApplicationsService);

			const allPriorEntities = await service.getAllLoanEntitiesForBankCustomer();

			// Add a hasBeenUsed flag to each entity
			const formattedPriorEntities = allPriorEntities.map(entity => {
				return {
					...entity,
					hasBeenUsed: false
				};
			});
			commit.SET_PRIOR_ENTITIES({ formattedPriorEntities });
		},

		// Given an entity ID, set the hasBeenUsed flag to true or false - when true, the entity will be hidden
		async setEntityHasBeenUsedFlag(context, { entityId, hasBeenUsed }: { entityId: number; hasBeenUsed: boolean }) {
			const { commit } = actionContext(context);

			// Make a shallow copy of the formattedPriorEntities array
			let updatedFormattedPriorEntities = [...context.state.formattedPriorEntities];
			updatedFormattedPriorEntities = updatedFormattedPriorEntities.map(entity => {
				if (entity.id === entityId) return { ...entity, hasBeenUsed };
				return entity;
			});

			commit.SET_PRIOR_ENTITIES({ formattedPriorEntities: updatedFormattedPriorEntities });
		},

		async updateLoanIndex(context, { loanIndex }: { loanIndex: number }) {
			const { commit, dispatch } = actionContext(context);
			commit.SET_CURRENT_LOAN_INDEX({ loanIndex });
			await dispatch.reset1071Flag({});
		},

		async setFormData(
			context,
			{ loanIndex, formData, presetDataFields }: { loanIndex: number; formData: AggregatedFormDataModel; presetDataFields: PresetDataFieldsByDataObject }
		) {
			const { state, commit, dispatch } = actionContext(context);

			// Only create submission data if it doesn't exist
			if (!state.submissionDataLookup[loanIndex]) {
				const submissionData = new FormDataSubmissionDataMapper(formData.dataObjects ?? {})
					.setPresetFields(presetDataFields)
					.setAggregatedFormData(formData)
					.getSubmissionData();

				commit.SET_SUBMISSION_DATA({ submissionData, loanIndex });
			}

			if (loanIndex === state.loanIndex) {
				await dispatch.reset1071Flag({});
			}
		},
		async clearCache(context) {
			const { commit } = actionContext(context);

			commit.CLEAR_SUBMISSION_DATA();
			commit.SET_RENDER_DATA({ renderData: undefined });
		},
		async loadAllFormData(context) {
			const { rootState, dispatch } = actionContext(context);
			const { metadata } = rootState.LoanApplicationMetadata;

			const loadDataPromises = metadata.map((_, index) => dispatch.loadLoanData({ loanIndex: index }));
			await Promise.all(loadDataPromises);
		},
		async loadEntitiesOnLoan(context): Promise<void> {
			const {
				state: { renderData = {} },
				commit
			} = actionContext(context);

			if (!renderData.formData) {
				return;
			}

			commit.SET_ENTITY_IDS_ON_LOAN({ entityIdsOnLoan: getEntitiesOnLoan(renderData.formData) });
		},
		async createNewProfitProperty(_, property: RealEstateProperty): Promise<RealEstateProperty> {
			const service = AutoFactory.get(OnlinePortalRealEstatePropertiesService);

			return service.create(property);
		},
		async setRealEstateFinancial(context, { realEstateFinancials }: { realEstateFinancials: Array<RealEstateFinancialData> }) {
			const { commit } = actionContext(context);

			commit.SET_REAL_ESTATE_FINANCIALS({ realEstateFinancials });
		},
		async setBusinessInvestments(context, { businessInvestments }: { businessInvestments: Array<BusinessInvestment> }) {
			const { commit } = actionContext(context);

			commit.SET_BUSINESS_INVESTMENTS({ businessInvestments });
		},
		async reset1071Flag(context, { value }: { value?: boolean }) {
			const { commit, rootState, getters } = actionContext(context);

			// If a value wasn't passed in then locate the value from the submission data
			if (value == null) {
				const { templateDataFieldIdToDataFieldId } = rootState.CustomComponentProperties as BusinessObjectPropertiesModuleState;
				const fieldId = templateDataFieldIdToDataFieldId[TemplateDataField.DidBusinessExceed5Million];

				// The field should always be in the same spot for us to check (aka living in "loans")
				value = getters.currentSubmissionData?.data?.['loans']?.data[formatDataFieldIdKey(fieldId?.toString())];
			}

			commit.SET_IS_1071_FLAG({ value: value === false });
		}
	},
	getters: {
		currentSubmissionData: (state: MultiLoanApplicationFormModuleState) => {
			return state.submissionDataLookup[state.loanIndex];
		},
		allSubmissionData: (state: MultiLoanApplicationFormModuleState) => {
			return state.submissionDataLookup;
		},
		proposedLoanId: (_, { currentMetadata }) => {
			return currentMetadata?.proposedLoanId;
		},
		currentMetadata: ({ loanIndex }: MultiLoanApplicationFormModuleState, _, rootState) => {
			const { metadata } = rootState.LoanApplicationMetadata as LoanApplicationMetadataModuleState;

			return metadata[loanIndex];
		},

		// The entity ID for entities assigned to various roles on the loan are stored in the form data in arrays
		// based on the index number - which is the id of the entry in the list of entities.  When one is selected
		// we bet the arrayIndex, but we need the entityID in order to do anything with it (like showing or hiding it)
		// Given the index and which component type, find the ID...
		getEntityId: (state: MultiLoanApplicationFormModuleState) => (arrayIndex: number, componentType: string) => {
			switch (componentType) {
				case LoanRoleType.CoBorrower:
					return state.renderData?.formData?.coBorrowerMappings?.[arrayIndex]?.customerID ?? 0;
				case LoanRoleType.Guarantor:
					return state.renderData?.formData?.guarantorMappings?.[arrayIndex]?.customerID ?? 0;
				case LoanRoleType.CoSigner:
					return state.renderData?.formData?.coSignerMappings?.[arrayIndex]?.customerID ?? 0;
				case LoanRoleType.PrimaryBorrower:
					// Primary Borrower can only ever have one entity so there is no array in the mapping...
					return state.renderData?.formData?.primaryBorrowerMapping?.customerID ?? 0;
				case LoanRoleType.CreditApplicant:
					return state.renderData?.formData?.creditApplicantMappings?.[arrayIndex]?.customerID ?? 0;
				default:
					return 0;
			}
		}
	}
});

export default MultiLoanApplicationFormModule;
