
import Vue, { PropType } from 'vue';
import TermsAndConditions from '../../components/terms-and-conditions/TermsAndConditions.vue';
import SubmitEsign from './submit-esign.vue';
import ReviewBody from './review-body.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { LoanApplication } from '@sageworks/jpi';
import { isInFinalStatus as isApplicationInFinalStatus } from '../../utils/application-final-status-helper/application-final-status-helper';

export default Vue.extend({
	name: 'SubmitBody',
	components: {
		TermsAndConditions,
		SubmitEsign,
		ReviewBody
	},
	props: {
		itemLabel: {
			type: String as PropType<string | null>,
			default: null
		},
		submittedDate: {
			type: Date as PropType<Date | null>,
			default: null
		},
		currentUserIsLender: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		canSubmitApplicationWithoutESignatures: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		isSubmitting: {
			type: Boolean as PropType<boolean>,
			default: false
		},
		isValidating: {
			type: Boolean as PropType<boolean>,
			default: false
		},
		allRecipientsHaveSignedAgreement: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		adobeAgreementCreated: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		customerCanUseEsign: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		applicationIdAsNumber: {
			type: Number as PropType<number | null>,
			default: null
		},
		preSubmitMessage: {
			type: String as PropType<string | null>,
			default: null
		},
		postSubmitMessage: {
			type: String as PropType<string | null>,
			default: null
		},
		loading: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		canSubmitBeforeLenderReview: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		canLenderMarkReadyForLenderReview: {
			type: Boolean as PropType<boolean | null>,
			default: null
		}
	},
	data() {
		return {
			internalTermsAccepted: false
		};
	},
	computed: {
		...mapGetters('LoanApplicationMetadata', ['loanRequiresReview']),
		submittedDateText(): string {
			const date = this.submittedDate;
			if (!date) {
				return '';
			}
			return moment(date).format('MM/DD/YYYY');
		},
		isValidatingOrSubmitting(): boolean {
			return this.isValidating || this.isSubmitting;
		},
		templateRequiresLenderReview(): boolean {
			return this.directStore.state.ApplicationTemplates.preAppTemplate?.applicationTemplateAdditionalSettings?.requiresReview ?? false;
		},
		applicationStatus(): LoanApplication.ApplicationStatusEnum {
			return this.directStore.state.LoanApplicationData.loanApplication?.applicationStatus ?? LoanApplication.ApplicationStatusEnum.New;
		},
		isInFinalStatus(): boolean {
			return isApplicationInFinalStatus(this.applicationStatus);
		},
		useStandardSubmitFlow(): boolean {
			return !this.loanRequiresReview || this.isInFinalStatus;
		}
	},
	mounted() {},
	methods: {
		showRecreateSignatureModal() {
			this.$emit('showRecreateSignatureModal');
		},
		submitApplicationWithoutESignatures() {
			this.$emit('submitApplicationWithoutESignatures');
		},
		submitApplicationBypassingReview() {
			this.$emit('submitApplicationBypassingReview');
		},
		submitApplication() {
			this.$emit('submitApplication');
		},
		sendEmailsForSignatures() {
			this.$emit('sendEmailsForSignatures');
		},
		markReadyForReview() {
			this.$emit('mark-ready-for-review');
		},
		readyForBorrowerToSubmit() {
			this.$emit('readyForBorrowerToSubmit');
		}
	}
});
